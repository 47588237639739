import React, { useState, useEffect } from "react";
import { useHistory,useLocation} from 'react-router-dom';
import '../ResponsiveCss/Responsive.css';
import CrudMethods from "../../config/CrudMethods";
import footer1 from '../../assets/images/footer1.png';
import footer2 from '../../assets/images/footer2.png';
import footer3on from '../../assets/images/footer3.png';
import footer3 from '../../assets/images/footer3-disabled.png';
import footer4 from '../../assets/images/footer4.png';





const Footer = () => {

    const search = useLocation().search;
    const test = new URLSearchParams(search).get('test');
   

    return (
        // <div className={"footer"}>
        //     {footer.value}
        // </div>
        <section class="footer-section">
	<div class="container">
        <div class="footer-panel">
            <h4><span>JOIN</span> OUR COMMUNITY</h4>
            <p>The global Goats Discord is live! Come join the community now to follow our latest announcements, and participate in giveaways.</p>
            <a href="https://t.co/AiSfFiJX3j" target="_blank" class="join-button">Join Discord</a>
        </div>   
        <div class="footer-links">
            <ul>
                <li> <a href="/#home">Home</a></li> 
                <li> <a href="/#about">About us</a></li>
                <li> <a href="/#roadmap">Roadmap</a></li>
                <li> <a href="/#faq">FAQ</a></li>
                <li> <a href="/PrivacyPolicy" target="_blank">Privacy Policy</a></li>
                <li> <a href="/Terms" target="_blank">Term of Use</a></li>
            </ul>
        </div>
        <div class="social-icons">
            <a href="https://twitter.com/GlobalGoatsNFT" target="_blank"><img src={footer4}/></a>
            <a href="https://t.co/AiSfFiJX3j" target="_blank"><img src={footer2}/></a>
         <a href="https://opensea.io/collection/globalgoatsnft" target="_blank"><img src={footer3on} /></a>
            <a href="#"><img src={footer1}/></a>
        </div>
	</div>
</section>

    )
}

export default Footer;

{/*
        <div >
             <div className={"gradient-shape"} />
        </div> 
    
        <div className={"footer-root"}>
                <span className={"text-style"}>&#169; 2021 CryptoRugs. All rights reserved.</span>
                <span ></span>
        </div>

    */}