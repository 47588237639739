import React, { useReducer, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useEthers } from "@usedapp/core";
import { doc, setDoc, getDoc, updateDoc, collection, getDocs } from "firebase/firestore";
import { db } from "../src/config/firebase-config";
// import { collection, getDocs } from "firebase/firestore";
import Footer from './components/Footer/Footer';
import Navbar from './components/NavBar/Navbar';
import AdminPanel from './containers/AdminPanel/AdminPanel';
import CreateNFT from './containers/CreateNFT/CreateNFT';
import EditHomePage from './containers/EditHomePage/EditHomePage';
import EditNFT from './containers/EditNFT/EditNFT';
import EditNFTForm from './containers/EditNFT/EditNFTForm';
import unAuthorized from '../src/components/unAuthorized/unAuthorized'
import LandingPage from './components/Landing/LandingPage';
import MyNFT from './components/MyNFT/MyNFT';
import useWindowDimensions from './utils/useWindowDimensions';
import CrudMethods from './config/CrudMethods.js';
import AdminDashboard from './containers/AdminPanel/AdminDashboard.js';
import BuyNFT from './components/BuyNFT/BuyNFT.js';
import adminupload from './containers/upload/adminupload';

import adminuploadregular from './containers/upload/adminuploadRegular.js';

import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy.js';
import TermsofSale from './components/TermsofSale/TermsofSale.js';
import GiveAways from './containers/AdminPanel/GiveAways.js';


const App = () => {
  const { innerHeight, innerWidth } = useWindowDimensions();
  const [admin, setAdmin] = useState(false)
  const { activateBrowserWallet, account, deactivate } = useEthers();
  const [listOfNFTtemp, setListOfNFTtemp] = useState([]);
  const [listOfNFT, setListOfNFT] = useState([]);
  // console.log = console.warn = console.error = () => {};

  console.log("account on App.js", account)
  //  <Route path='/AdminPanel/' component={admin?AdminPanel:unAuthorized}/>

  
  useEffect(() => {
    if(account){
      getUserDetails()
    }
  }, [account]);

  useEffect(() => {
    if(account){
      getUserDetails()
    }
  }, [admin]);
  const getUserDetails = async() => {
    var address = account.toLowerCase();
    console.log("address ==== ",address)
    const docRef = doc(db, "Users", address);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      console.log("Document data in app.js:", docSnap.data());
      var userDetails=docSnap.data();
      console.log("userDetails",userDetails.IsAdmin)
      var  checkadmin = userDetails.IsAdmin
      console.log("checkadmin",checkadmin)
      if(checkadmin == true){
        setAdmin(true);
        console.log("Admin in true section",admin)
      }
      else if(checkadmin == false){
        console.log("Admin in false section",admin)
        setAdmin(false);
      }
    else{
      console.log("Admin in  section",admin)
     
    }
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  }


  return (
    <Router basename={"/"}>
      {/* Navigation Header */}
      {/* <Navbar height={innerHeight} width={innerWidth} /> */}
      <Switch>
        <Route path='/' component={LandingPage} exact />
        {/* <Route path='/mynft/' component={MyNFT} /> */}
       <Route path='/MyNft/' component={MyNFT} />
        {console.log("admin is",admin)}
        <Route path='/AdminPanel/' component={admin?AdminPanel:unAuthorized}/>
        <Route path='/EditHomePage/' component={EditHomePage} />
        <Route path='/EditNFT/' component={EditNFT} />
        <Route path='/EditNFTForm/' component={EditNFTForm} />
        <Route path='/AdminDashboard/' component={admin?AdminDashboard:unAuthorized} />
        <Route path='/createnft/' component={CreateNFT} />
        <Route path='/BuyNft/' component={BuyNFT} />
        <Route path='/adminupload/' component={adminupload} />

        
        <Route path='/adminuploadregular/' component={adminuploadregular} />
        <Route path='/giveAways/' component={GiveAways} />


        <Route path='/Terms/' component={TermsofSale} />
        <Route path='/PrivacyPolicy/' component={PrivacyPolicy} />


      </Switch>
      {/* Footer */}
      {/* <Footer /> */}
    </Router>
  );
}

export default App;