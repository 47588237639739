import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useEthers } from "@usedapp/core";
import { doc, setDoc,getDoc,updateDoc } from "firebase/firestore";
import { db } from "./../../config/firebase-config";
import { getApp } from "firebase/app";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import '../AdminPanel/AdminCss.css'
import './CreateNFT.css';
import { FontAwesome } from 'react-web-vector-icons';
import Footer from '../../components/Footer/Footer.js';
import Navbar from '../../components/NavBar/Navbar.js';
import Sidebar from '../AdminPanel/Sidebar.js';
import AWS from 'aws-sdk'





const S3_BUCKET = 'assetsglobalgoats';
const REGION = 'us-east-1';

AWS.config.update({
  accessKeyId: 'AKIASHE3U75274I4PUNG',
  secretAccessKey: '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'
})

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
})

const CreateNFT = () => {
  let history = useHistory();
  const [formTokenID, setFormTokenID] = useState('');
  const [nftName, setNftName] = useState('');
  const [nftPrice, setNftPrice] = useState('');
  const [nftDescription, setNftDescription] = useState('');
  const [nftImage, setNftImage] = useState('');
  const [activityStatus, setActivityStatus] = useState(false);
  const [progress, setProgress] = useState(0);

  const firebaseApp = getApp();
  const storage = getStorage(firebaseApp);


  useEffect(async () => {
    const docRef = doc(db, "TokenTrack", "lastTokenId");
   const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      console.log("Document data TokenTrack:", docSnap.data());
      var getData=docSnap.data();
      var LastTokenId=getData.lastTokenId;
      console.log("LastTokenId",LastTokenId)

       var mainTokenId=LastTokenId + 1;
      setFormTokenID(mainTokenId);
      console.log("formTokenId",formTokenID)
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  },[]);

  function isInt(str) {
    return !isNaN(str) && Number.isInteger(parseFloat(str));
  }

  const PublishNFT = async (awsnftImg) => {
    var baseUrl;
    if (
      nftDescription.trim() == "" || nftDescription.trim() == null
      || nftName.trim() == "" || nftName.trim() == null
    ) {
      alert("All fields are required")
    } else if (!isInt(formTokenID) || parseFloat(formTokenID) <= 0) {
      alert("Invalid Entries! Please check all details again.")
    } else {
      var nftImageUrl = nftImage;
      if (nftImage.name) {
        const storageRef = ref(storage, '/Images/NFTImages/' + nftName + "_" + formTokenID + ".png");
        await uploadBytes(storageRef, nftImage);
        await getDownloadURL(storageRef).then((url) => { nftImageUrl = url });
        console.log("nftImageUrl ======== " + nftImage + " ------ " + nftImageUrl)
      }

      // const washingtonRef = doc(db, "NFTList", formTokenID.toString());
      var NFTDetails = {
        description: nftDescription.trim(),
        image: awsnftImg,
        name: nftName.trim(),
        tokenid: formTokenID,
      }
      const myJSON = JSON.stringify(NFTDetails);
      var NFTDetailKey = 'tokens/' + formTokenID + '/metadata.json';
      console.log("NFTDetails", NFTDetails)
      const params = {
        ACL: 'public-read',
        Body: myJSON,
        Bucket: S3_BUCKET,
        Key: NFTDetailKey,
        ContentType: 'application/json'
      };

      myBucket.putObject(params)
        .on('httpUploadProgress', async (evt) => {
          console.log("evnt is", evt)
          baseUrl = 'https://assetsglobalgoats.s3.amazonaws.com/' + NFTDetailKey;
          console.log("Main Url", baseUrl)
          // var NFTDetailsFirebase = {
          //   id: formTokenID,
          //   nftUrl: baseUrl,
          //   nftDescription: nftDescription.trim(),
          //   nftImage: awsnftImg,
          //   nftStatus: "",
          //   price: '',
          //   nftName: nftName.trim(),
          //   tokenID: formTokenID,
          //   tokenUri: "",
          //   transactionStatus: 0,
          //   owner: ""
          // }
          // const aaa = await setDoc(washingtonRef, NFTDetailsFirebase);
          const washingtonRef2 = doc(db, "TokenTrack", 'lastTokenId');
          var TokenDetails = {
            lastTokenId: formTokenID,
          }
    
          // // Set the "capital" field of the city 'DC'
          const bbb = await updateDoc(washingtonRef2, TokenDetails);
          console.log("End Url", NFTDetailKey)
          setProgress(Math.round((evt.loaded / evt.total) * 100))
        })
        .send((err) => {
          if (err) console.log(err)
        })
       


      setFormTokenID("")
      setNftDescription("")
      setNftImage("")
      setNftName("")
      alert("NFT Successfully Published.");
      window.location.reload();

    }
  }

  const onHeaderChoose = event => {
    if (event.target.files && event.target.files[0]) {
      setNftImage(event.target.files[0]);
    }
  };

  const handleUpload = async (file) => {
    var aaa = [2,4,7,9,19,23,27,37,40,41,44,74,79,89,101,107,133,144,156,164,165,169,173,196,199,201,204,209,215,218,227,228,230,237,256,265,275,277,280,283,292,303,312,315,317,336,337,342,344,349,432,480,585,667,712,786,792,821,867,893,926,931,939,976,981,1083,1098,1100,1166,1223,1230,1295,1352,1400,1461,1551,1571,1619,1639,1645,1658,1678,1697,1754,1771,1901,1919,1926,1944,1978,1985,2018,2022,2085,2125,2175,2222,2244,2261,2323]
    for(var i = 1; i<=100 ; i++){
      var imageId = 'tokens/' + formTokenID + '/metadata.png';
        const params = {
          ACL: 'public-read',
          Body: file,
          Bucket: S3_BUCKET,
          Key: imageId,
          ContentType: 'image/png'
        };
      }



    // myBucket.putObject(params)
    //   .on('httpUploadProgress', (evt) => {
    //     console.log("evnt is", evt)
    //     var baseUrl = 'https://assetsglobalgoats.s3.amazonaws.com/' + imageId;
    //     // PublishNFT(baseUrl);
    //     // setProgress(Math.round((evt.loaded / evt.total) * 100))
    //   })
    //   .send((err) => {
    //     if (err) console.log(err)
    //   })

  

  }
  return (
    <div>
      <Sidebar />
      <div class="admin-working-panel">
        <div className={"root-div"}>
          <div className={"sub-root-div12"} style={{ marginBottom: 100 }}>
            <div style={{ marginBottom: 30, marginTop: 60, }}>
              <span style={{ fontSize: 24, fontWeight: 'bold', borderRadius: 50, padding: '5px 30px', backgroundColor: '#eee' }}>{'NFT'}</span>
            </div>
            <div className='grey-box-css'>
              <div style={{ marginBottom: 30, padding: 20, display: 'flex', flexDirection: 'column' }}>
                {/* <div className='inside-modal-input'>
                  <label style={{ flex: 0.4, textAlign: 'left', fontWeight: 'bold', marginRight: 20 }}>Token ID</label>
                  <input
                    style={{ flex: 0.6, padding: '10px 15px', minWidth: '150px' }}
                    type="text"
                    placeholder='Token ID'
                    value={formTokenID}
                    onChange={(e) => { setFormTokenID(e.target.value) }}
                  />
                </div> */}
                <div className='inside-modal-input'>
                  <label style={{ flex: 0.4, textAlign: 'left', fontWeight: 'bold', marginRight: 20 }}>NFT Name</label>
                  <input
                    style={{ flex: 0.6, padding: '10px 15px', minWidth: '150px' }}
                    type="text"
                    placeholder='NFT Name'
                    name="nftName"
                    value={nftName}
                    onChange={(e) => { setNftName(e.target.value) }}
                  />
                </div>
                <div className='inside-modal-input'>
                  <label style={{ flex: 0.4, textAlign: 'left', fontWeight: 'bold', marginRight: 20 }}>NFT Image</label>
                  <div style={{ flex: 0.6 }}>
                    <input style={{ marginBottom: 20 }} onChange={onHeaderChoose} type="file" name="myImage" />
                    {nftImage && (<><br />
                      <img src={nftImage.name ? URL.createObjectURL(nftImage) : nftImage} style={{ height: 150, padding: 10, backgroundColor: '#000' }} alt="NFT Image" /></>)}
                  </div>
                </div>
                <div className='inside-modal-input'>
                  <label style={{ flex: 0.4, textAlign: 'left', fontWeight: 'bold', marginRight: 20 }}>NFT Description</label>
                  <textarea
                    style={{ flex: 0.6, padding: '10px 15px', maxHeight: 100, minHeight: 100 }}
                    type="text"
                    placeholder='NFT Description'
                    name="nftDescription"
                    value={nftDescription}
                    onChange={(e) => { setNftDescription(e.target.value) }}
                    rows={5}
                    cols={5}
                  />
                </div>
              </div>
              <button onClick={() => handleUpload(nftImage)} style={{ backgroundColor: '#000', color: '#fff', display: 'flex', width: '100%', padding: '10px', justifyContent: 'center', border: 'none', borderRadius: 50 }}>
                <span style={{ fontSize: 18, fontWeight: 'bold' }}>Publish NFT</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <Footer/> */}
    </div>
  );
};

export default CreateNFT;