import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useEthers } from "@usedapp/core";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "./../../config/firebase-config";
import { getApp } from "firebase/app";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import CrudMethods from '../../config/CrudMethods';
import { OutTable, ExcelRenderer } from 'react-excel-renderer';

import '../EditHomePage/EditHomePage.css';
import { FontAwesome } from 'react-web-vector-icons';
import Footer from '../../components/Footer/Footer.js';
import Navbar from '../../components/NavBar/Navbar.js';
import AWS from 'aws-sdk'

const S3_BUCKET = 'assetsglobalgoats';
const REGION = 'us-east-1';

AWS.config.update({
  accessKeyId: 'AKIASHE3U75274I4PUNG',
  secretAccessKey: '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'
})

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
})

const EditHomePage = () => {
  let history = useHistory();
  
  const [scrollingImages, setScrollingImages] = useState([]);
  const [scrollingImagesRegularGoat, setScrollingImagesRegularGoat] = useState([]);
  
  
  const fileHandlerRegularGoat = (event) => {
    var abc = [2,4,7,9,19,23,27,37,40,41,44,74,79,89,101,107,133,144,156,164,165,169,173,196,199,201,204,209,215,218,227,228,230,237,256,265,275,277,280,283,292,303,312,315,317,336,337,342,344,349,432,480,585,667,712,786,792,821,867,893,926,931,939,976,981,1083,1098,1100,1166,1223,1230,1295,1352,1400,1461,1551,1571,1619,1639,1645,1658,1678,1697,1754,1771,1901,1919,1926,1944,1978,1985,2018,2022,2085,2125,2175,2222,2244,2261,2323];
    let fileObj = event.target.files[0];

      //just pass the fileObj as parameter
      ExcelRenderer(fileObj, (err, resp) => {
          if (err) {
              console.log(err);
          }
          else {
            // console.log("response ======== ", resp)
              var abc111 = 0;
              for (var i = 1; i <= 2323; i++) {
                  if(abc.includes(i)){
                  } else {
                    console.log("abc111 ----- "+ abc111);
                    var aaa = (resp.rows[abc111][0]).split('#');
                    console.log("id ============ "+ aaa[1]+" ---- "+i)
                      for (var j = 0; j < resp.rows[abc111].length; j++) {
                        var NFTDetailsMeta = {
                            "tokenid": i,
                            "name":resp.rows[abc111][0],
                            "symbol":resp.rows[abc111][1],
                            "description":resp.rows[abc111][2],
                            "image":"https://assets.globalgoats.io/PrabhTest/"+i+"/metadata.png",
                            "attributes":[
                              {
                                  "trait_type":"backgrounds",
                                  "value":resp.rows[abc111][4]
                              },
                              {
                                  "trait_type":"skin color",
                                  "value":resp.rows[abc111][5]
                              },
                              {
                                  "trait_type":"mouth",
                                  "value":resp.rows[abc111][6]
                              },
                              {
                                  "trait_type":"eyes",
                                  "value":resp.rows[abc111][7]
                              },
                              {
                                  "trait_type":"eyewear",
                                  "value":resp.rows[abc111][8]
                              },
                              {
                                  "trait_type":"clothes",
                                  "value":resp.rows[abc111][9]
                              },
                              {
                                  "trait_type":"accessories",
                                  "value":resp.rows[abc111][10]
                              },
                              {
                                  "trait_type":"chiva",
                                  "value":resp.rows[abc111][11]
                              },
                              {
                                  "trait_type":"hairstyle",
                                  "value":resp.rows[abc111][12]
                              },
                              {
                                  "trait_type":"holding in hand",
                                  "value":resp.rows[abc111][13]
                              },
                              {
                                  "trait_type":"BLOCKHEADS NFT collection",
                                  "value":resp.rows[abc111][14]
                              }
                            ]
                        }
                      }
                      abc111++;
                      // var NFTDetails = {
                      //     tokenid: resp.rows[i][0],
                      //     name: resp.rows[i][1],
                      //     image: resp.rows[i][2],
                      //     description: resp.rows[i][3],
                      // }
                  }

              //Json File
                  const myJSON = JSON.stringify(NFTDetailsMeta);
                  console.log("json file", myJSON);
                  // const washingtonRef = doc(db, "NFTList", NFTDetailsMeta.tokenid.toString());
              //upload on s3 and save in firebase from createnft.js logic
                  var NFTDetailKey = 'PrabhTest/' + NFTDetailsMeta.tokenid + '/metadata.json';
                  console.log("NFTDetails", NFTDetailsMeta)
                  const params = {
                      ACL: 'public-read',
                      Body: myJSON,
                      Bucket: S3_BUCKET,
                      Key: NFTDetailKey,
                      ContentType: 'application/json'
                  };

                  myBucket.putObject(params)
                      .on('httpUploadProgress', async (evt) => {
                          console.log("evnt is", evt)
                      })
                      .send((err) => {
                          if (err) console.log(err)
                      })
              }

              console.log("response ============ ", resp)
          }
      });

  }


  const onScrollSelectRegularGoat = async(event) => {
    var abc = [2,4,7,9,19,23,27,37,40,41,44,74,79,89,101,107,133,144,156,164,165,169,173,196,199,201,204,209,215,218,227,228,230,237,256,265,275,277,280,283,292,303,312,315,317,336,337,342,344,349,432,480,585,667,712,786,792,821,867,893,926,931,939,976,981,1083,1098,1100,1166,1223,1230,1295,1352,1400,1461,1551,1571,1619,1639,1645,1658,1678,1697,1754,1771,1901,1919,1926,1944,1978,1985,2018,2022,2085,2125,2175,2222,2244,2261,2323];
    // if (event.target.files && event.target.files[0] && (event.target.files).length >= 5 && (event.target.files).length <= 10) {
      var list = [];
      var abc111 = 0;
      for (var i = 1; i <= 2323; i++) {
        if(abc.includes(i)){
        } else {
          var checkFileName = (event.target.files[abc111].name).split(".png");
          console.log('file name111', checkFileName[0]);
          var c = abc111+1;
          if(c==checkFileName[0]) {
            console.log('file name', c + " ----- " + i);
            list.push(event.target.files[abc111])

              var imageId = 'PrabhTest/' + i + '/metadata.png';
              const params = {
                ACL: 'public-read',
                Body: event.target.files[abc111],
                Bucket: S3_BUCKET,
                Key: imageId,
                ContentType: 'image/png'
              };
          
              await myBucket.putObject(params)
              .on('httpUploadProgress', (evt) => {
                console.log("evnt is ===== "+abc111 + " ----- " + i, evt)
                // var baseUrl = 'https://assetsglobalgoats.s3.amazonaws.com/' + imageId;
                // PublishNFT(baseUrl);
                // setProgress(Math.round((evt.loaded / evt.total) * 100))
              })
              .send((err) => {
                if (err) console.log(err)
              })
          }
          abc111++;
        }
      }
      //setScrollingImagesRegularGoat(list);
      //alert('Images Rendered .Please wait to be uploaded on S3')
  };



  





  const UpdateSmartContractRegularGoat = async() => {
    

    var scrollingList = [];
    if(scrollingImages[0].name){
      for(var i=0 ; i<scrollingImages.length ; i++){
        console.log("scrollingList 11111 ======== ", scrollingImages.length);
        const storageRef = ref(storage, '/Images/Assets/scrollImage'+i+'.png');
        await uploadBytes(storageRef, scrollingImages[i]);
        var scrollImageURL = "";
        await getDownloadURL(storageRef).then((url)=>{scrollImageURL = url});  
        scrollingList.push(scrollImageURL);
      }
      console.log("scrollingList ======== ", JSON.stringify(scrollingList));
    } else {
      scrollingList = scrollingImages;
    }
    
  
  
    // history.goBack();
    alert("Updated Successfully.");
  // }
}









 
  
  const UpdateSmartContract = async() => {
    

      var scrollingList = [];
      if(scrollingImages[0].name){
        for(var i=0 ; i<scrollingImages.length ; i++){
          console.log("scrollingList 11111 ======== ", scrollingImages.length);
          const storageRef = ref(storage, '/Images/Assets/scrollImage'+i+'.png');
          await uploadBytes(storageRef, scrollingImages[i]);
          var scrollImageURL = "";
          await getDownloadURL(storageRef).then((url)=>{scrollImageURL = url});  
          scrollingList.push(scrollImageURL);
        }
        console.log("scrollingList ======== ", JSON.stringify(scrollingList));
      } else {
        scrollingList = scrollingImages;
      }
      
    
    
      // history.goBack();
      alert("Updated Successfully.");
    // }
  }

  // const onHeaderChoose = event => {
  //   if (event.target.files && event.target.files[0]) {
  //     let img = event.target.files[0];
  //     setHeadImage(img)
  //     console.log(img)
  //   }
  // };

  const [fileName,setFileName]=useState('');

  const fileHandler = (event) => {
    var abc = [2,4,7,9,19,23,27,37,40,41,44,74,79,89,101,107,133,144,156,164,165,169,173,196,199,201,204,209,215,218,227,228,230,237,256,265,275,277,280,283,292,303,312,315,317,336,337,342,344,349,432,480,585,667,712,786,792,821,867,893,926,931,939,976,981,1083,1098,1100,1166,1223,1230,1295,1352,1400,1461,1551,1571,1619,1639,1645,1658,1678,1697,1754,1771,1901,1919,1926,1944,1978,1985,2018,2022,2085,2125,2175,2222,2244,2261,2323];
    let fileObj = event.target.files[0];

      //just pass the fileObj as parameter
      ExcelRenderer(fileObj, (err, resp) => {
          if (err) {
              console.log(err);
          }
          else {
            // console.log("response ======== ", resp)

              for (var i = 0; i < resp.rows.length; i++) {
                  
                  for (var j = 0; j < resp.rows[i].length; j++) {
                    var aaa = (resp.rows[i][0]).split('#');
                    console.log("aaaid ============ ", aaa[1]);
                      if(aaa[1]==abc[i]){
                        console.log("id ============ ", aaa[1])
                        var NFTDetailsMeta = {
                            "tokenid": aaa[1],
                            "name":resp.rows[i][0],
                            "symbol":resp.rows[i][1],
                            "description":resp.rows[i][2],
                            "image":"https://assets.globalgoats.io/tokens/"+aaa[1]+"/metadata.png",
                            "attributes":[
                              {
                                  "trait_type":"backgrounds",
                                  "value":resp.rows[i][4]
                              },
                              {
                                  "trait_type":"skin color",
                                  "value":resp.rows[i][5]
                              },
                              {
                                  "trait_type":"mouth",
                                  "value":resp.rows[i][6]
                              },
                              {
                                  "trait_type":"eyes",
                                  "value":resp.rows[i][7]
                              },
                              {
                                  "trait_type":"eyewear",
                                  "value":resp.rows[i][8]
                              },
                              {
                                  "trait_type":"clothes",
                                  "value":resp.rows[i][9]
                              },
                              {
                                  "trait_type":"accessories",
                                  "value":resp.rows[i][10]
                              },
                              {
                                  "trait_type":"chiva",
                                  "value":resp.rows[i][11]
                              },
                              {
                                  "trait_type":"hairstyle",
                                  "value":resp.rows[i][12]
                              },
                              {
                                  "trait_type":"holding in hand",
                                  "value":resp.rows[i][13]
                              },
                              {
                                  "trait_type":"BLOCKHEADS NFT collection",
                                  "value":resp.rows[i][14]
                              }
                            ]
                        }
                      }
                    
                  }

              //Json File
                  const myJSON = JSON.stringify(NFTDetailsMeta);
                  console.log("json file", myJSON);
                  // const washingtonRef = doc(db, "NFTList", NFTDetailsMeta.tokenid.toString());
              //upload on s3 and save in firebase from createnft.js logic
                  var NFTDetailKey = 'NitishTest/' + NFTDetailsMeta.tokenid + '/metadata.json';
                  console.log("NFTDetails", NFTDetailsMeta)
                  const params = {
                      ACL: 'public-read',
                      Body: myJSON,
                      Bucket: S3_BUCKET,
                      Key: NFTDetailKey,
                      ContentType: 'application/json'
                  };

                  myBucket.putObject(params)
                      .on('httpUploadProgress', async (evt) => {
                          console.log("evnt is", evt)
                      })
                      .send((err) => {
                          if (err) console.log(err)
                      })
              }

              console.log("response ============ ", resp)
          }
      });

  }


  const onScrollSelect = event => {
    var abc = [2,4,7,9,19,23,27,37,40,41,44,74,79,89,101,107,133,144,156,164,165,169,173,196,199,201,204,209,215,218,227,228,230,237,256,265,275,277,280,283,292,303,312,315,317,336,337,342,344,349,432,480,585,667,712,786,792,821,867,893,926,931,939,976,981,1083,1098,1100,1166,1223,1230,1295,1352,1400,1461,1551,1571,1619,1639,1645,1658,1678,1697,1754,1771,1901,1919,1926,1944,1978,1985,2018,2022,2085,2125,2175,2222,2244,2261,2323];
    // if (event.target.files && event.target.files[0] && (event.target.files).length >= 5 && (event.target.files).length <= 10) {
      var list = [];
      for(var i=0 ; i<(event.target.files).length ; i++){
        var checkFileName = (event.target.files[i].name).split(".png");
        console.log('file name111', checkFileName[0]);
        var c = i+1;
        if(c==checkFileName[0]) {
          console.log('file name', c + " ----- " + abc[i]);
          list.push(event.target.files[i])

            var imageId = 'NitishTest/' + abc[i] + '/metadata.png';
            const params = {
              ACL: 'public-read',
              Body: event.target.files[i],
              Bucket: S3_BUCKET,
              Key: imageId,
              ContentType: 'image/png'
            };
        
            myBucket.putObject(params)
            .on('httpUploadProgress', (evt) => {
              console.log("evnt is ===== "+i + " ----- " + abc[i], evt)
              // var baseUrl = 'https://assetsglobalgoats.s3.amazonaws.com/' + imageId;
              // PublishNFT(baseUrl);
              // setProgress(Math.round((evt.loaded / evt.total) * 100))
            })
            .send((err) => {
              if (err) console.log(err)
            })
        }
      }
      setScrollingImages(list);
      alert('Images Uploaded.')
    // } else {
    //   alert('Please select images between 5-10')
    // }
  };

  // const onWhiteBoxChoose = event => {
  //   if (event.target.files && event.target.files[0]) {
  //     let img = event.target.files[0];
  //     console.log("onWhiteBoxChoose",img)
  //     setWhiteBoxImage(img)
  //   }
  // };

  return (
    <div>
          {/* <Navbar height={innerHeight} width={innerWidth} /> */}
    <div className={"root-div"}>
    <div className={"sub-root-div12"} style={{marginBottom:100}}>
      <div className={"homepage-head"} style={{height: '100%', width: '100%'}}>
        <div className={"h1-style1"}>{"Admin Upload Section"}</div>
      </div>
      <div style={{ marginBottom: 30, marginTop:60, }}>
        <span style={{ fontSize: 24, fontWeight: 'bold', borderRadius:50, padding: '5px 30px', backgroundColor: '#eee' }}>{'Admin uploads'}</span>
      </div>
      <div className='grey-box-css' style={{margin:15}}>
          <div style={{marginBottom: 30, padding:20, display:'flex', flexDirection:'column'}}>
            
            <div className='inside-modal-input-scroll'>
              <label style={{flex:0.4, textAlign:'left', fontWeight:'bold', marginRight:20}}>Scrolling Images (Between 5-10)</label>
              <div style={{flex:0.6, width:'100%'}}>
                <input onChange={onScrollSelect} type="file" name="myImage" multiple/>
                <br/>
                { scrollingImagesRegularGoat && scrollingImagesRegularGoat.length>=5 && scrollingImagesRegularGoat.length<=10 && 
                  (
                    <div className='scroll-image-div'>
                      {
                        (scrollingImagesRegularGoat).map((link) =>
                          {return <img src={link.name?URL.createObjectURL(link):link} style={{height:150, margin:10}} alt="Scrolling Regular Goat Image"/>}
                        )
                      }
                    </div>
                  )
                }
              </div>
            </div>


             {/* Regular Goat */}
            <div className='inside-modal-input-scroll'>
              <label style={{flex:0.4, textAlign:'left', fontWeight:'bold', marginRight:20}}>Scrolling Images Regular (All)</label>
              <div style={{flex:0.6, width:'100%'}}>
                <input onChange={onScrollSelectRegularGoat} type="file" name="myImage" multiple/>
                <br/>
                { scrollingImages && scrollingImages.length>=5 && scrollingImages.length<=10 && 
                  (
                    <div className='scroll-image-div'>
                      {
                        (scrollingImages).map((link) =>
                          {return <img src={link.name?URL.createObjectURL(link):link} style={{height:150, margin:10}} alt="Scrolling Image"/>}
                        )
                      }
                    </div>
                  )
                }
              </div>
            </div>






            {/* <button onClick={()=>{UpdateSmartContract()}} style={{backgroundColor:'#000', color:'#fff', display:'flex', width:'100%', padding: '10px', justifyContent:'center', border:'none', borderRadius:50}}>
            <span style={{fontSize:18, fontWeight:'bold'}}>Upload Image</span>
          </button> */}
            
          </div>

          <div class="admin-working-panel">
          <h1>Choose Excel File </h1>
              <input onChange={(event) => {setFileName(event) }} type="file" style={{ "padding": "10px" }} />

              <button onClick={()=>{ fileHandler(fileName)}} style={{ backgroundColor: '#000', color: '#fff', display: 'flex', width: '100%', padding: '10px', justifyContent: 'center', border: 'none', borderRadius: 50 }}>
              <span style={{ fontSize: 18, fontWeight: 'bold' }}>Publish Metadata</span>
            </button>
        
          </div>

          
      </div>
    </div>
    </div>
    <Footer/>
    </div>
  );
};

export default EditHomePage;