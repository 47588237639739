import React from 'react'
import '../ResponsiveCss/Responsive.css';
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import Image1 from '../../assets/images/collection/1.png';
import Image2 from '../../assets/images/collection/2.png';
import Image3 from '../../assets/images/collection/3.png';
import Image4 from '../../assets/images/collection/4.png';
import Image5 from '../../assets/images/collection/5.png';
import Image6 from '../../assets/images/collection/6.png';
import Image7 from '../../assets/images/collection/7.png';
import Image8 from '../../assets/images/collection/8.png';
import Image9 from '../../assets/images/collection/9.png';
import Image10 from '../../assets/images/collection/10.png';
import Image11 from '../../assets/images/collection/11.png';
import Image12 from '../../assets/images/collection/12.png';
import Image13 from '../../assets/images/collection/13.png';
import Image14 from '../../assets/images/collection/14.png';
import Image15 from '../../assets/images/collection/15.png';
import Image16 from '../../assets/images/collection/16.png';
import Image17 from '../../assets/images/collection/17.png';

var imgArray=[
  {image:Image1},
  {image:Image2},
  {image:Image3},
  {image:Image4},
  {image:Image5},
  {image:Image6},
  {image:Image7},
  {image:Image8},
  {image:Image9},
  {image:Image10},
  {image:Image11},
  {image:Image12},
  {image:Image13},
  {image:Image14},
  {image:Image15},
  {image:Image16},
  {image:Image17},



]


export default function OurCollection() {




 
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 3
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
      
    return (
        <section class="fourth-section" id="collection">
        <div class="container">
           <h2>OUR COLLECTION</h2>
           <span>23 is the ultimate GOAT number! Our entire collection is made up of 2323 unique Goats.</span>
            <div class="slider">
           <Carousel
            centerMode={false}
            swipeable={true}
            draggable={true}
            //   showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            //   autoPlay={true}
            //   autoPlaySpeed={800}
            keyBoardControl={true}
            //   customTransition={300}
            transitionDuration={400}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            //   deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-20-px"
          >
              {imgArray.map((item) => (
            <div><img src={item.image} alt="Italian Trulli" /></div>
            ))}
          </Carousel>
            </div>
        </div>
    </section>
    )
}
