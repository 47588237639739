import React, { useContext } from 'react';
import '../ResponsiveCss/Responsive.css';
import Aboutimg1 from '../../assets/images/aboutimg1.png';
import Aboutimg2 from '../../assets/images/aboutimg2.png';
import Aboutimg3 from '../../assets/images/aboutimg3.png';
import Aboutimg4 from '../../assets/images/aboutimg4.png';



export default function About() {
    return (
        <div>
            <section class="third-section" id="about">
    <div class="container">
    <h2>ABOUT US</h2>
    <span>Pregame Mint Price = 0.11 ETH <br/> Public Mint Price = 0.15 ETH</span>
    <p>Minting 1 of our 2323 Global Goats will give you ultimate status and networking in the sports industry! Each GOAT will receive:</p>
    <ul>
        <li>Private discord chat and weekly video lounge with sports celebrities, athletes, and influencers. </li>

        <li>Free Rolex Submariner watch to 1 of our first 500 minted Goats.</li>

        <li>At halftime, 50% minted, we will give 0.1 ETH (approximately $500) to each of our 50 hardest working Goats who are most active in discord.
</li>
        <li>Incredible giveaways, airdrops, future meet ups, and future NFTs. (please scroll and read the roadmap)</li>

        <li>Opportunity to randomly mint 1 of our 100 VIP Golden Goats! More info below:</li>
        
    </ul>
    
    <h5>VIP Golden Goats</h5>  
    <div class="gold_section">         
                   <img src={Aboutimg1}></img>          
                   <img src={Aboutimg2}></img>          
                   <img src={Aboutimg3}></img>
                   <img src={Aboutimg4}></img>         
    </div>
    <ul>
        <li>100 of our 2323 Goats will be Golden!</li>
        <li>Golden GOATS, our most exclusive design, will be randomly minted.</li>
        <li>50 Golden Goats will be set aside for pre-sale, and the other 50 for public sale. </li>
        <li> Teamwork makes the dream work. That’s why 2% of all future GOAT re-sales on our website platform will be evenly distributed back to our 100 Golden Goat holders. A great way to make passive income simply by holding your Golden Goat. </li>
        <li> For example, in the future, let’s say it’s a Monday and there are 10 GOATS sold on Our Platform for a total of $10,000. This means a 2% sales royalty ($200) will be automatically, and evenly, distributed to the 100 Golden Goats. (paid in ETH.) </li>
        <li> This might sound small, but it’s a great way to make passive income that can really add up quickly over time. Especially once the value of our GOATs rises to thousands of dollars each, and the trading volume rises to 100+ transactions a day.</li> 
    </ul>
    </div>
</section>
        </div>
    )
}

