import React from 'react'
import AdminHeader from './AdminHeader.js'
import Sidebar from './Sidebar.js'

export default function AdminDashboard() {
    return (
        <div>
            <Sidebar/>
            <div class="admin-working-panel"><h1>Welcome Admin</h1></div>
            </div>
    )
}
