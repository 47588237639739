import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useEthers } from "@usedapp/core";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "./../../config/firebase-config";
import { getApp } from "firebase/app";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import CrudMethods from '../../config/CrudMethods';

import './EditHomePage.css';
import { FontAwesome } from 'react-web-vector-icons';
import Footer from '../../components/Footer/Footer.js';
import Navbar from '../../components/NavBar/Navbar.js';

const EditHomePage = () => {
  let history = useHistory();
  const [navHeading, setNavHeading] = useState('');
  const [headImage, setHeadImage] = useState('');
  const [headingLeftWhite, setHeadingLeftWhite] = useState('');
  const [headingMiddleRed, setHeadingMiddleRed] = useState('');
  const [headingRightWhite, setHeadingRightWhite] = useState('');
  const [headDescription, setHeadDescription] = useState('');
  const [price, setPrice] = useState('');
  const [headButtonText, setHeadButtonText] = useState('');
  const [scrollingImages, setScrollingImages] = useState([]);
  const [whiteBoxHeading, setWhiteBoxHeading] = useState('');
  const [whiteBoxPara1, setWhiteBoxPara1] = useState('');
  const [whiteBoxPara2, setWhiteBoxPara2] = useState('');
  const [whiteBoxImage, setWhiteBoxImage] = useState('');
  const [footer, setFooter] = useState('');


  const firebaseApp = getApp();
  const storage = getStorage(firebaseApp);
  
  useEffect(async() => {
    console.log("useeffect is set")
    // SmartContractDetails()
    getHomePageDetails();
  },[]);

  const getHomePageDetails = async() => {
    const docRef2 = doc(db, "Settings", "2");
    const docSnap2 = await getDoc(docRef2);

    const docRef3 = doc(db, "Settings", "3");
    const docSnap3 = await getDoc(docRef3);

    const docRef4 = doc(db, "Settings", "4");
    const docSnap4 = await getDoc(docRef4);

    const docRef5 = doc(db, "Settings", "5");
    const docSnap5 = await getDoc(docRef5);


    const docRef6 = doc(db, "Settings", "6");
    const docSnap6 = await getDoc(docRef6);

    const docRef7 = doc(db, "Settings", "7");
    const docSnap7 = await getDoc(docRef7);

    const docRef8 = doc(db, "Settings", "8");
    const docSnap8 = await getDoc(docRef8);

    const docRef9 = doc(db, "Settings", "9");
    const docSnap9 = await getDoc(docRef9);

    const docRef10 = doc(db, "Settings", "10");
    const docSnap10 = await getDoc(docRef10);

    const docRef11 = doc(db, "Settings", "11");
    const docSnap11 = await getDoc(docRef11);

    const docRef12 = doc(db, "Settings", "12");
    const docSnap12 = await getDoc(docRef12);

    const docRef13 = doc(db, "Settings", "13");
    const docSnap13 = await getDoc(docRef13);

    const docRef14 = doc(db, "Settings", "14");
    const docSnap14 = await getDoc(docRef14);

    const docRef15 = doc(db, "Settings", "15");
    const docSnap15 = await getDoc(docRef15);

    // if (docSnap1.exists()) {
      const data2 = docSnap2.data();
      const data3 = docSnap3.data();
      const data4 = docSnap4.data();
      const data5 = docSnap5.data();
      const data6 = docSnap6.data();
      const data7 = docSnap7.data();
      const data8 = docSnap8.data();
      const data9 = docSnap9.data();
      const data10 = docSnap10.data();
      const data11 = docSnap11.data();
      const data12 = docSnap12.data();
      const data13 = docSnap13.data();
      const data14 = docSnap14.data();
      const data15 = docSnap15.data();
   

      setFooter(data2.value);
      setHeadButtonText(data3.value);
      setHeadDescription(data4.value);
      setHeadImage(data5.value);
      setHeadingLeftWhite(data6.value);
      setHeadingMiddleRed(data7.value);
      setHeadingRightWhite(data8.value);
      setNavHeading(data9.value);
      setPrice(data10.value);
      setScrollingImages(data11.value);
      setWhiteBoxHeading(data12.value);
      setWhiteBoxImage(data13.value);
      setWhiteBoxPara1(data14.value);
      setWhiteBoxPara2(data15.value);  
      // doc.data() will be undefined in this case
    
  }

  
  const UpdateSmartContract = async() => {
    console.log("navHeading",navHeading)
    if(
        navHeading.trim() == "" || navHeading.trim() == null || 
        headingLeftWhite.trim() == "" || headingLeftWhite.trim() == null ||
        headingMiddleRed.trim() == "" || headingMiddleRed.trim() == null ||
        headingRightWhite.trim() == "" || headingRightWhite.trim() == null ||
        headDescription.trim() == "" || headDescription.trim() == null ||
        headButtonText.trim() == "" || headButtonText.trim() == null ||
        whiteBoxHeading.trim() == "" || whiteBoxHeading.trim() == null ||
        whiteBoxPara1.trim() == "" || whiteBoxPara1.trim() == null ||
        whiteBoxPara2.trim() == "" || whiteBoxPara2.trim() == null ||
        footer.trim() == "" || footer.trim() == null
    ){
        alert("All fields are required")
    } 
    else if(scrollingImages.length<0) {
        alert( "Select atleast 5 scrolling images.")
    } else if (isNaN(price) || parseFloat(price)<=0) {
       alert("Invalid Price! Please check details again.")
    } else {
      var headImageUrl = headImage;
      if(headImage.name){
        const storageRef = ref(storage, '/Images/Assets/headImage.png');
        await uploadBytes(storageRef, headImage);
        await getDownloadURL(storageRef).then((url)=>{headImageUrl = url});  
        console.log("headImageUrl ======== "+headImage+" ------ "+ headImageUrl)
        console.log("headImageurl",headImageUrl)
      }

      var scrollingList = [];
      if(scrollingImages[0].name){
        for(var i=0 ; i<scrollingImages.length ; i++){
          console.log("scrollingList 11111 ======== ", scrollingImages.length);
          const storageRef = ref(storage, '/Images/Assets/scrollImage'+i+'.png');
          await uploadBytes(storageRef, scrollingImages[i]);
          var scrollImageURL = "";
          await getDownloadURL(storageRef).then((url)=>{scrollImageURL = url});  
          scrollingList.push(scrollImageURL);
        }
        console.log("scrollingList ======== ", JSON.stringify(scrollingList));
      } else {
        scrollingList = scrollingImages;
      }
      
      var whiteBoxImageUrl = headImage;
      if(whiteBoxImage.name){
        const storageRef = ref(storage, '/Images/Assets/whiteBoxImage.png');
        await uploadBytes(storageRef, whiteBoxImage);
        await getDownloadURL(storageRef).then((url)=>{whiteBoxImageUrl = url});  
        console.log("whiteBoxImageUrl ======== "+whiteBoxImage+" ------ "+ whiteBoxImageUrl)
      }

      const washingtonRef = doc(db, "Settings", "10");
      const washingtonRef6 = doc(db, "Settings", "6");
      const washingtonRef11 = doc(db, "Settings", "11");
      const washingtonRef12 = doc(db, "Settings", "12");
      const washingtonRef13 = doc(db, "Settings", "13");
      const washingtonRef14 = doc(db, "Settings", "14");
      const washingtonRef15 = doc(db, "Settings", "15");
      const washingtonRef8 = doc(db, "Settings", "8");
      const washingtonRef7 = doc(db, "Settings", "7");
      const washingtonRef4 = doc(db, "Settings", "4");
      const washingtonRef5 = doc(db, "Settings", "5");
      const washingtonRef3 = doc(db, "Settings", "3");
      const washingtonRef2 = doc(db, "Settings", "2");
      const washingtonRef9 = doc(db, "Settings", "9");
        console.log("Scrolling images",scrollingImages)
      var HomeDetailsPrice = {
        value: parseFloat(price)
      }
      var HomescrollingImages = {
        value:scrollingList
      }
      var HomeWhiteBoxHeading = {
        value:whiteBoxHeading.trim()
      }
      var HomeWhiteBoxImg = {
        value:whiteBoxImageUrl
      }
      var HomewhiteBoxPara1 = {
        value:whiteBoxPara1.trim()
      }
      var HomewhiteBoxPara2 = {
        value:whiteBoxPara2.trim()
      }
      var HomeheadingRightWhite = {
        value:headingRightWhite.trim()
      }
      var HomeheadingMiddleRed = {
        value:headingMiddleRed.trim()
      }
      var HomeheadingLeftWhite = {
        value: headingLeftWhite.trim()
      }
      var HomeheadDescription = {
        value:headDescription.trim()
      }
      var HomeheadImage = {
        value:headImageUrl
      }
      var HomeheadButtonText = {
        value:headButtonText.trim()
      }
      var Homefooter = {
        value:footer.trim()
      }
      var HomenavHeading = {
        value:navHeading.trim()
      }
      
      const aaa = await updateDoc(washingtonRef, HomeDetailsPrice);
      const bbb = await updateDoc(washingtonRef6, HomeheadingLeftWhite);
      const ccc = await updateDoc(washingtonRef11, HomescrollingImages);
      const eee = await updateDoc(washingtonRef12, HomeWhiteBoxHeading);
      const fff = await updateDoc(washingtonRef13, HomeWhiteBoxImg);
      const ggg = await updateDoc(washingtonRef14, HomewhiteBoxPara1);
      const hhh = await updateDoc(washingtonRef15, HomewhiteBoxPara2);
      const iii = await updateDoc(washingtonRef8, HomeheadingRightWhite);
      const jjj = await updateDoc(washingtonRef7, HomeheadingMiddleRed);
      const kkk = await updateDoc(washingtonRef4, HomeheadDescription);
      const lll = await updateDoc(washingtonRef5, HomeheadImage);
      const mmm = await updateDoc(washingtonRef3, HomeheadButtonText);
      const nnn = await updateDoc(washingtonRef2, Homefooter);
      const ooo = await updateDoc(washingtonRef9, HomenavHeading);


      // // Set the "capital" field of the city 'DC'
    
      // history.goBack();
      alert("Updated Successfully.");
    }
  }

  const onHeaderChoose = event => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setHeadImage(img)
      console.log(img)
    }
  };

  const onScrollSelect = event => {
    if (event.target.files && event.target.files[0] && (event.target.files).length >= 5 && (event.target.files).length <= 10) {
      var list = [];
      for(var i=0 ; i<(event.target.files).length ; i++){
          list.push(event.target.files[i])
      }
      setScrollingImages(list)
    } else {
      alert('Please select images between 5-10')
    }
  };

  const onWhiteBoxChoose = event => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      console.log("onWhiteBoxChoose",img)
      setWhiteBoxImage(img)
    }
  };

  return (
    <div>
          {/* <Navbar height={innerHeight} width={innerWidth} /> */}
    <div className={"root-div"}>
    <div className={"sub-root-div12"} style={{marginBottom:100}}>
      <div className={"homepage-head"} style={{height: '100%', width: '100%'}}>
        <div className={"h1-style1"}>{"Edit Home Page"}</div>
      </div>
      <div style={{ marginBottom: 30, marginTop:60, }}>
        <span style={{ fontSize: 24, fontWeight: 'bold', borderRadius:50, padding: '5px 30px', backgroundColor: '#eee' }}>{'Home Page'}</span>
      </div>
      <div className='grey-box-css' style={{margin:15}}>
          <div style={{marginBottom: 30, padding:20, display:'flex', flexDirection:'column'}}>
            <div className='inside-modal-input'>
              <label style={{flex:0.4, textAlign:'left', fontWeight:'bold', marginRight:20}}>Title</label>
              <input 
                style={{flex:0.6, padding: '10px 15px'}}
                type="text" 
                name="navHeading"
                value={navHeading}
                onChange={(e) => {setNavHeading(e.target.value)}}
              />
            </div>
            <div className='inside-modal-input'>
              <label style={{flex:0.4, textAlign:'left', fontWeight:'bold', marginRight:20}}>Header Image</label>
              <div style={{flex:0.6}}>
                <input style={{marginBottom:20}} onChange={onHeaderChoose} type="file" name="myImage"/>
                {headImage && (<><br/>
                  <img src={headImage.name?URL.createObjectURL(headImage):headImage} style={{height:150, padding:10, backgroundColor:'#000'}} alt="White Box Image"/></>)}
              </div>
            </div>
            <div className='inside-modal-input'>
              <label style={{flex:0.4, textAlign:'left', fontWeight:'bold', marginRight:20}}>Heading-Left-White</label>
              <input 
                style={{flex:0.6, padding: '10px 15px'}}
                type="text" 
                name="headingLeftWhite"
                value={headingLeftWhite}
                onChange={(e) => {setHeadingLeftWhite(e.target.value)}}
              />
            </div>
            <div className='inside-modal-input'>
              <label style={{flex:0.4, textAlign:'left', fontWeight:'bold', marginRight:20}}>Heading-Middle-Red</label>
              <input 
                style={{flex:0.6, padding: '10px 15px'}}
                type="textarea" 
                name="headingMiddleRed"
                value={headingMiddleRed}
                onChange={(e) => {setHeadingMiddleRed(e.target.value)}}
              />
            </div>
            <div className='inside-modal-input'>
              <label style={{flex:0.4, textAlign:'left', fontWeight:'bold', marginRight:20}}>Heading-Right-White</label>
              <input 
                style={{flex:0.6, padding: '10px 15px'}}
                type="textarea" 
                name="headingRightWhite"
                value={headingRightWhite}
                onChange={(e) => {setHeadingRightWhite(e.target.value)}}
              />
            </div>
            <div className='inside-modal-input'>
              <label style={{flex:0.4, textAlign:'left', fontWeight:'bold', marginRight:20}}>Header Description</label>
              <textarea 
                style={{flex:0.6, padding: '10px 15px', maxHeight:100, minHeight:100}}
                type="text" 
                name="headDescription"
                value={headDescription}
                onChange={(e) => {setHeadDescription(e.target.value)}}
                rows={5}
                cols={5}
              />
            </div>
            <div className='inside-modal-input'>
              <label style={{flex:0.4, textAlign:'left', fontWeight:'bold', marginRight:20}}>Price</label>
              <input 
                style={{flex:0.6, padding: '10px 15px'}}
                type="textarea" 
                name="price"
                value={price}
                onChange={(e) => {setPrice(e.target.value)}}
              />
            </div>
            <div className='inside-modal-input'>
              <label style={{flex:0.4, textAlign:'left', fontWeight:'bold', marginRight:20}}>Header Button Text</label>
              <input 
                style={{flex:0.6, padding: '10px 15px'}}
                type="textarea" 
                name="headButtonText"
                value={headButtonText}
                onChange={(e) => {setHeadButtonText(e.target.value)}}
              />
            </div>
            <div className='inside-modal-input-scroll'>
              <label style={{flex:0.4, textAlign:'left', fontWeight:'bold', marginRight:20}}>Scrolling Images (Between 5-10)</label>
              <div style={{flex:0.6, width:'100%'}}>
                <input onChange={onScrollSelect} type="file" name="myImage" multiple/>
                <br/>
                { scrollingImages && scrollingImages.length>=5 && scrollingImages.length<=10 && 
                  (
                    <div className='scroll-image-div'>
                      {
                        (scrollingImages).map((link) =>
                          {return <img src={link.name?URL.createObjectURL(link):link} style={{height:150, margin:10}} alt="Scrolling Image"/>}
                        )
                      }
                    </div>
                  )
                }
              </div>
            </div>
            <div className='inside-modal-input'>
              <label style={{flex:0.4, textAlign:'left', fontWeight:'bold', marginRight:20}}>White Para Heading</label>
              <input 
                style={{flex:0.6, padding: '10px 15px'}}
                type="textarea" 
                name="whiteBoxHeading"
                value={whiteBoxHeading}
                onChange={(e) => {setWhiteBoxHeading(e.target.value)}}
              />
            </div>
            <div className='inside-modal-input'>
              <label style={{flex:0.4, textAlign:'left', fontWeight:'bold', marginRight:20}}>White Box Para - 1</label>
              <textarea 
                style={{flex:0.6, padding: '10px 15px', maxHeight:100, minHeight:100}}
                type="text" 
                name="whiteBoxPara1"
                value={whiteBoxPara1}
                onChange={(e) => {setWhiteBoxPara1(e.target.value)}}
                rows={5}
                cols={5}
              />
            </div>
            <div className='inside-modal-input'>
              <label style={{flex:0.4, textAlign:'left', fontWeight:'bold', marginRight:20}}>White Box Para - 2</label>
              <textarea 
                style={{flex:0.6, padding: '10px 15px', maxHeight:100, minHeight:100}}
                type="text" 
                name="whiteBoxPara2"
                value={whiteBoxPara2}
                onChange={(e) => {setWhiteBoxPara2(e.target.value)}}
                rows={5}
                cols={5}
              />
            </div>
            <div className='inside-modal-input'>
              <label style={{flex:0.4, textAlign:'left', fontWeight:'bold', marginRight:20}}>White Box Image</label>
              <div style={{flex:0.6}}>
                <input style={{marginBottom:20}} onChange={onWhiteBoxChoose} type="file" name="myImage"/>
                {whiteBoxImage && (<><br/>
                  <img src={whiteBoxImage.name?URL.createObjectURL(whiteBoxImage):whiteBoxImage} style={{height:150, padding:10, backgroundColor:'#000'}} alt="White Box Image"/></>)}
              </div>
            </div>
            <div className='inside-modal-input'>
              <label style={{flex:0.4, textAlign:'left', fontWeight:'bold', marginRight:20}}>Footer</label>
              <input 
                style={{flex:0.6, padding: '10px 15px'}}
                type="textarea" 
                name="footer"
                value={footer}
                onChange={(e) => {setFooter(e.target.value)}}
              />
            </div>
          </div>
          <button onClick={()=>{UpdateSmartContract()}} style={{backgroundColor:'#000', color:'#fff', display:'flex', width:'100%', padding: '10px', justifyContent:'center', border:'none', borderRadius:50}}>
            <span style={{fontSize:18, fontWeight:'bold'}}>Update Home Page</span>
          </button>
      </div>
    </div>
    </div>
    <Footer/>
    </div>
  );
};

export default EditHomePage;