import React from 'react'
import Footer from '../Footer/Footer.js';
import Navbar from '../NavBar/Navbar.js';
import '../ResponsiveCss/Responsive.css'

export default function PrivacyPolicy() {
    return (
        <div>
      <div class="first-section">
        <div class="container">
          <Navbar />
        </div>
      </div>
      <section class="third-section">
        <div class="container">
          <h2>Privacy Policy</h2>
          <h6>Last revised: January 3, 2022</h6>
<p>These Terms of Use (“Terms”) apply to your purchase, sale and display of Global Goats non-fungible tokens (“NFTs”). Some purchases of NFTs may include special experience opportunities. To the extent there is a conflict between these Terms and the Experience Terms, these Terms control.</p>
<p>These Terms are entered into between you and Global Goats, LLC (“Company,” “we,” or “us”). These Terms expressly incorporate any other documents referenced herein (such as our Privacy Policy) and govern your access to and use of this site https://personalcornernft.io (the “Site”), as well as all content, functionality, and services offered on or through the Site, including the NFTs.</p>
<h6>Reviewing and Accepting These Terms</h6>
<p>Please read these Terms carefully, as they set out your rights and responsibilities when you use this Site to buy NFTs (the “Services”). When each NFT is sold for the first time, the agreement for sale is between the Company and the initial purchaser. If the initial purchaser decides to sell the NFT through this Site, then this Site serves only as a platform that facilitates transactions between a buyer and a seller and the Company is not a party to any agreement between such buyer and seller of NFTs or between any other users.</p>
<p>All NFTs are stored on and accessible through the Ethereum blockchain. As such, the Company does not maintain the NFTs on this Site and, aside from transferring control of the NFT to the initial purchaser of the NFT, the Company has no control over the transfer, storage, ownership or maintenance of the NFT.</p>
<p>When you connect your cryptocurrency wallet to the Site using a trusted service provide such as MetaMask or Wallet Connect, you accept and agree to be bound and abide by these Terms and all of the terms incorporated herein by reference. By agreeing to these Terms, you hereby certify that you are at least 18 years of age. If you do not agree to these Terms, you must not access or use the Site.</p>
<p>Please note that Section 17 contains an arbitration clause and class action waiver. By agreeing to these Terms, you agree to resolve all disputes through binding individual arbitration, which means that you waive any right to have the dispute decided by a judge or jury, and you waive any right to participate in collective action, whether that be a class action, class arbitration, or representative action. You have the ability to opt-out of this arbitration clause by sending us notice of your intent to do so within thirty (30) days of your initial agreement to these Terms.</p><p>We reserve the right to change or modify these Terms at any time and in our sole discretion. You agree and understand that by accessing or using the Site following any change to these Terms, you are agreeing to the revised Terms and all of the terms incorporated therein by reference.</p>
<p>Review the Terms each time you access the Site to ensure that you understand how the Terms apply to your activities on the Site.</p>
<h6>Linking Your Cryptocurrency Wallet</h6>
<p>When you link your cryptocurrency wallet, you understand and agree that you are solely responsible for maintaining the security of your wallet and your control over any wallet-related authentication credentials, private or public cryptocurrency keys, non-fungible tokens or cryptocurrencies that are stored in or are accessible through your wallet. Any unauthorized access to your cryptocurrency wallet by third parties could result in the loss or theft of NFTs and/or funds held in your wallet and any associated wallets, including any linked financial information such as bank account(s) or credit card(s). We are not responsible for managing and maintaining the security of your cryptocurrency wallet nor for any unauthorized access to or use of your cryptocurrency wallet. If you notice any unauthorized or suspicious activity in your cryptocurrency wallet that seems to be related to this Site, please notify us immediately.</p>
<h6>Ownership</h6>
<p>Unless otherwise indicated in writing by us, the Site, all content, and all other materials contained therein, including, without limitation, Our logos, and all designs, text, graphics, pictures, information, data, software, sound files, other files, and the selection and arrangement thereof (collectively, “Site Content”) are the proprietary property of Global Goats or our affiliates, licensors, or users, as applicable. The Global Goats logo and any Global Goats product or service names, logos, or slogans that may appear on the Site or elsewhere are trademarks of Global Goats or our affiliates, and may not be copied, imitated or used, in whole or in part, without our prior written permission.</p>
<p>You may not use any Site Content or link to the Site without our prior written permission. You may not use framing techniques to enclose any Site Content without our express written consent. In addition, the look and feel of the Site Content, including without limitation, all page headers, custom graphics, button icons, and scripts constitute the service mark, trademark, or trade dress of Global Goats and may not be copied, imitated, or used, in whole or in part, without our prior written permission.</p>
<h6>Terms of Sale</h6>
<p>By placing an order on the Site, you agree that you are submitting a binding offer to purchase an NFT or other Service. If you are the initial purchaser of a NFT or you are purchasing a Service, then all amounts due are to be paid to Global Goats, LLC. If you are not the initial purchaser of a NFT, then amounts may be paid to the-then holder of the NFT. You also acknowledge and agree that Company receives 10% of every subsequent sale of a NFT (“Royalty”). For example, if you are the initial purchaser, and you sell a NFT for $100 to a subsequent purchaser, $10 will automatically be transferred to Company and you will receive $90. Company has the right collect Royalties for NFT sales in perpetuity and may use those funds in any manner Company sees fit.</p>
<p>As such, if you sell a NFT on a third-party NFT marketplace, you agree to include a statement substantially similar to the following in the description of the NFT:</p><p>“10% Royalty Applies. See Global Goats Terms for details.”</p>
<p>In addition, when you buy or sell a NFT on this Site, you agree to pay all applicable fees associated with the transaction and you authorize Us to automatically charge and collect such fees from your payment. We will always display a breakdown of any transaction or other fees prior to your purchase or sale of a NFT.</p>
<p>No refunds are permitted except with respect to any statutory warranties or guarantees that cannot be excluded or limited by law.</p>
<h6>Intellectual Property</h6>
<p>Other than Site Content, all other trademarks, product names, and logos on the Site are the property of their respective owners and may not be copied, imitated, or used, in whole or in part, without the permission of the applicable trademark holder. Without limiting the foregoing, if you believe that third-party material hosted on the Site infringes your copyright or trademark rights, please file a notice of infringement by contacting the Designated Copyright Agent listed below.</p>
<p>Your notice must contain the following information as required by the Digital Millennium Copyright Act (17 U.S.C. §512) (“DMCA”):</p>
<p><ul><li>The full name and a physical or electronic signature of the person authorized to act on behalf of the copyright owner;</li><li>Identification of the copyrighted work claimed to have been infringed. If multiple copyrighted works are covered by your notice, you may provide a representative list of the copyrighted works that you claim have been infringed;</li><li>Reasonably sufficient detail to enable us to identify and locate the copyrighted work that is claimed to be infringing (e.g. a link to the page on the Site that contains the material);</li><li>A mailing address, telephone number, and email address where we can contact you;</li><li>A statement that you have a good faith belief that the disputed use of the copyrighted work is not authorized by the copyright owner, its agent, or the law;</li><li>A statement made by you, under penalty of perjury, that the information in the notice is accurate and that you are the copyright owner or authorized to act on behalf of the copyright owner.</li></ul></p>





<p>Please submit your notice to the Designated Agent below:</p><p>Global Goats, LLC</p><p>Email: globalgoatsnft@gmail.com</p>
<p>Once you provide us with an adequate notice as described above, we will respond expeditiously and take whatever action, in our sole discretion, that is deemed appropriate including removal of the disputed copyrighted work from the Site.</p>
<h6>Counter-Notice</h6>
<p>If you believe that a DMCA notice of copyright infringement has been improperly submitted against you, you may submit a counter-notice to the Designated Agent with the following information required by the DMCA:</p>
<p><ul><li>Your physical or electronic signature.</li><li>Identification of the copyrighted work that has been removed or to which access has been disabled including a link to the page on the Site that contained the material before it was removed or disabled.</li><li>A statement under penalty of perjury that you have a good faith belief that the copyrighted work was removed or disabled as a result of mistake or misidentification.</li><li>Your name, address, e-mail address, and telephone number.</li><li>A statement that you (i) consent to the jurisdiction of the Federal District Court in the judicial district where your address is located if the address is in the United States, or the United District Court for Dallas Texas if your address is located outside of the United States, and (ii) accept service of process from the person who provided the DMCA notice of the alleged copyright infringement.</li></ul></p>




<p>Please submit your counter-notice to the Designated Agent below:</p>
<p>Global Goats, </p>
<p> LLCLegal Department</p>
<p>   Email: globalgoatsnft@gmail.com</p>
<p>In the event that the Company receives a counter-notice in compliance with the above requirements, we will provide the person who submitted the DMCA copyright infringement notice with a copy of the counter-notice, informing them that the Company will replace the removed material in 10 business days from the date of the counter-notice unless the Company first receives notice from the person who submitted the DMCA copyright infringement notice that they have filed an action seeking a court order to restrain the allegedly infringing activity.</p>
<p>PLEASE NOTE THAT Global Goats INTENDS TO COMPLY WITH ALL PROVISIONS OF THE DIGITAL MILLENNIUM COPYRIGHT ACT, BUT WILL NOT UNILATERALLY TAKE RESPONSIBILITY FOR POLICING AND REMOVING MATERIAL THOUGHT TO BE INFRINGING.</p>
<p>We hereby grant you a limited, non-exclusive, non-transferable, revocable license to access and use the Site Content. In return, you agree not to engage, or assist, in any activity that violates any law, statute, ordinance, regulation, or sanctions program, including but not limited to the U.S. Department of Treasury’s Office of Foreign Assets Control (“OFAC”), or that involves proceeds of any unlawful activity; not to engage in any other activity or behavior that poses a threat to Global Goats, LLC, (e.g., by distributing a virus or other harmful code, or through unauthorized access to the Site or other users’ cryptocurrency wallets and not to interfere with other users’ access to or use of the Services.</p>
<p>You also agree not to (1) distribute, publish, broadcast, reproduce, copy, retransmit, or publicly display any Site Content; (2) modify or create derivative works from the Site Content, or any portion thereof; (3) use any data mining, robots, or similar data gathering or extraction methods on the Site Content; (4) download any portion of the Site Content, other than for purposes of page caching, except as expressly permitted by us.</p>
<p>With respect to the NFTs, each purchaser of a NFT is granted an exclusive, limited license to such NFT and its content to access, use, or store such NFT and its content solely for their personal, non-commercial purposes. NFTs are a limited-edition digital creation based upon content that may be trademarked and/or copyrighted by Company. Unless otherwise specified, your purchase of a NFT does not give you the right to publicly display, perform, distribute, sell or otherwise reproduce the NFT or its content for any commercial purpose. You further agree that you are not receiving any copyright interest in the NFT or its content, and indeed, you agree that Company may sell, license, modify, display, broadcast and create derivative works based upon your NFT or its content. Any commercial exploitation of the NFT could subject you to claims of copyright infringement. If you sell a NFT through the Site, you agree that you will not have any claims against us for any breach of these Terms by a purchaser. If you purchase a NFT on the Site, you hereby agree to hold us and the seller of such NFT harmless from and against any and all violations or breaches of these Terms.</p>
<p>If you are unsure whether a contemplated use of the Site Content or a NFT and its content would violate these Terms, please contact us at hello@personalcorner.com.</p>
<h6>Taxes</h6>
<p>We are not responsible for determining the withholding, sales, use, value added, transfer or other taxes, together with any interest and penalties imposed with respect thereto (“Taxes”), that may apply to transactions on this Site. You agree that you are solely responsible for determining what, if any, Taxes apply to your transactions and to withhold, collect, report and remit the correct amounts of Taxes to the appropriate taxing authorities. Unless otherwise indicated on an applicable invoice, amounts due on this Site are exclusive of sale, use, value added or similar Taxes (“Sales Taxes”). This means that Sales Taxes become your sole responsibility. Upon our request, you agree to promptly provide a properly executed Internal Revenue Service Form W-9 or applicable Internal Revenue Service W-8 and any other tax form that is reasonably required by us so to comply with our tax reporting obligations.</p>
<h6>Privacy</h6>
<p>You acknowledge and agree to the collection, use, and disclosure of your personal information in accordance with our Privacy Policy, which is incorporated into these Terms.</p>
<h6>Modifications</h6>
<p>You agree and understand that we may modify part or all of this Site or the Services without notice, and that we may update these Terms and any other document incorporated by reference therein at any time.</p>
<h6>Risks</h6>
<p>Please note the following risks in accessing, purchasing, selling or using NFTs: The price and liquidity of blockchain assets, including NFTs, are extremely volatile and may be subject to large fluctuations. Fluctuations in the price of other digital assets could materially and adversely affect NFTs, which may also be subject to significant price volatility. Legislative and regulatory changes or actions at the state, federal, or international level may adversely affect the use, transfer, exchange, and value of NFTs. NFTs are not legal tender and are not backed by any government. Transactions in NFTs may be irreversible, and, accordingly, losses due to fraudulent or accidental transactions may not be recoverable. Some transactions in NFTs shall be deemed to be made when recorded on a public ledger, which is not necessarily the date or time that you initiated the transaction. The value of NFTs may be derived from the continued willingness of market participants to exchange fiat currency or digital assets for NFTs, which may result in the potential for permanent and total loss of value of a particular NFT should the market for that NFT disappear.</p>
<p>You agree and understand that you are solely responsible for determining the nature, potential value, suitability, and appropriateness of these risks for yourself, and that we do not give advice or recommendations regarding NFTs, including the suitability and appropriateness of, and investment strategies for, NFTs. You agree and understand that you access and use this Site at your own risk; however, this brief statement does not disclose all of the risks associated with NFTs and other digital assets. You agree and understand that we will not be responsible for any communication failures, disruptions, errors, distortions or delays you may experience when using NFTs, however caused.<br></br>Disclaimers</p>
<p>EXCEPT AS EXPRESSLY PROVIDED TO THE CONTRARY IN A WRITING BY Global Goats, LLC, THE SITE AND ANY SITE CONTENT CONTAINED THEREIN, AND ANY AND ALL NFTS LISTED THEREIN ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED. Global Goats (AND ITS SUPPLIERS) MAKE NO WARRANTY THAT THE SITE WILL (1) MEET YOUR REQUIREMENTS; (2) BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS; OR (3) BE ACCURATE, RELIABLE, COMPLETE, LEGAL, OR SAFE.<br></br>WE WILL NOT BE LIABLE FOR ANY LOSS OF ANY KIND FROM ANY ACTION TAKEN OR TAKEN IN RELIANCE ON MATERIAL OR INFORMATION, CONTAINED ON THE SITE. Global Goats DOES NOT REPRESENT OR WARRANT THAT SITE CONTENT IS ACCURATE, COMPLETE, RELIABLE, CURRENT OR ERROR-FREE.</p>
<p>WHILE WE ATTEMPT TO MAKE YOUR ACCESS TO AND USE OF THE SITE AND SITE CONTENT SAFE, WE CANNOT AND DO NOT REPRESENT OR WARRANT THAT THE SITE, SITE CONTENT, ANY NFTS LISTED ON OUR SITE OR OUR SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. WE CANNOT GUARANTEE THE SECURITY OF ANY DATA THAT YOU DISCLOSE ONLINE. YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING INFORMATION AND DEALING ONLINE OVER THE INTERNET AND WILL NOT HOLD US RESPONSIBLE FOR ANY BREACH OF SECURITY UNLESS IT IS DUE TO OUR GROSS NEGLIGENCE.</p>
<p>WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSS AND TAKE NO RESPONSIBILITY FOR, AND WILL NOT BE LIABLE TO YOU FOR, ANY USE OF NFTS INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES OR CLAIMS ARISING FROM: (A) USER ERROR SUCH AS FORGOTTEN PASSWORDS, INCORRECTLY CONSTRUCTED TRANSACTIONS, OR MISTYPED ADDRESSES; (B) SERVER FAILURE OR DATA LOSS; (C) CORRUPTED WALLET FILES; (D) UNAUTHORIZED ACCESS TO APPLICATIONS; (E) ANY UNAUTHORIZED THIRD PARTY ACTIVITIES, INCLUDING WITHOUT LIMITATION THE USE OF VIRUSES, PHISHING, BRUTE FORCING OR OTHER MEANS OF ATTACK AGAINST THE SITE OR NFTS.</p>
<p>NFTS ARE INTANGIBLE DIGITAL ASSETS. THEY EXIST ONLY BY VIRTUE OF THE OWNERSHIP RECORD MAINTAINED IN THE ETHEREUM NETWORK. ANY TRANSFER OF TITLE THAT MIGHT OCCUR IN ANY UNIQUE DIGITAL ASSET OCCURS ON THE DECENTRALIZED LEDGER WITHIN THE ETHEREUM PLATFORM. WE DO NOT GUARANTEE THAT Global Goats, LLC OR ANY Global Goats, LLC PARTY CAN EFFECT THE TRANSFER OF TITLE OR RIGHT IN ANY NFT.</p>
<p>We are not responsible for sustained casualties due to vulnerability or any kind of failure, abnormal behavior of software (e.g., wallet, smart contract), blockchains or any other features of the NFTs. Global Goats, LLC is not responsible for casualties due to late report by developers or representatives (or no report at all) of any issues with the blockchain supporting NFTs including forks, technical node issues or any other issues having fund losses as a result.</p>
<p>Nothing in these Terms shall exclude or limit liability of either party for fraud, death or bodily injury caused by negligence, violation of laws, or any other activity that cannot be limited or excluded by legitimate means.</p>
<p>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES IN CONTRACTS WITH CONSUMERS, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.</p>
<h6>Limitation of Liability</h6>
<p>TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL Global Goats, LLC BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM THESE TERMS, THE SITE, SITE CONTENT, THE SERVICES OR THIRD PARTY SITES AND PRODUCTS, OR FOR ANY DAMAGES RELATED TO LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, OR LOSS OF DATA, WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE AND EVEN IF Global Goats, LLC HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, THE SITE, SITE CONTENT, THE SERVICES OR THIRD PARTY SITES AND PRODUCTS ARE AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF DATA RESULTING THEREFROM.</p>
<p>NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, IN NO EVENT SHALL THE MAXIMUM AGGREGATE LIABILITY OF Global Goats ARISING OUT OF OR IN ANY WAY RELATED TO THIS AGREEMENT, THE ACCESS TO AND USE OF THE SITE, SITE CONTENT, NFTS, OR ANY SERVICES PURCHASED ON THE SITE EXCEED THE GREATER OF (A) $100 OR (B) THE AMOUNT YOU HAVE PAID TO Global Goats, LLC FOR THE SERVICES IN THE LAST TWELVE MONTHS OUT OF WHICH LIABILITY AROSE.</p>
<h6>Indemnification</h6>
<p>To the fullest extent permitted by applicable law, you agree to indemnify, defend and hold harmless Global Goats, LLC, and our respective past, present and future employees, officers, directors, contractors, consultants, equity holders, suppliers, vendors, service providers, parent companies, subsidiaries, affiliates, agents, representatives, predecessors, successors and assigns (individually and collectively, the “Global Goats Parties”), from and against all actual or alleged third party claims, damages, awards, judgments, losses, liabilities, obligations, penalties, interest, fees, expenses (including, without limitation, attorneys’ fees and expenses) and costs (including, without limitation, court costs, costs of settlement and costs of pursuing indemnification and insurance), of every kind and nature whatsoever, whether known or unknown, foreseen or unforeseen, matured or unmatured, or suspected or unsuspected, in law or equity, whether in tort, contract or otherwise (collectively, “Claims”), including, but not limited to, damages to property or personal injury, that are caused by, arise out of or are related to (a) your use or misuse of the Site, Site Content, or NFTs, (c) your violation of these Terms, (c) your violation of the rights of a third party, including another user and (e) your failure to pay any Taxes or Sales Taxes in connection with your transactions on this Site or to provide us with a properly executed tax form described in Section 8. You agree to promptly notify us of any third party Claims and cooperate with the Global Goats Parties in defending such Claims. You further agree that the Global Goats Parties shall have control of the defense or settlement of any third party Claims. THIS INDEMNITY IS IN ADDITION TO, AND NOT IN LIEU OF, ANY OTHER INDEMNITIES SET FORTH IN A WRITTEN AGREEMENT BETWEEN YOU AND US.</p>
        </div>
      </section>
      <Footer />
    </div>
    )
}

