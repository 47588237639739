import React from 'react'
import '../ResponsiveCss/Responsive.css';
import RoadMapImg from '../../assets/images/roadmap-img.png'
export default function RoadMap() {
    return (
        <div>
           <section class="fifth-section" id="roadmap">
    <div class="container">
       <h2>ROAD MAP</h2>
       <div class="road-map">
            <div class="box-1">
                <div class="t-camp">
                    <h5>Training Camp</h5>
                    <ul>
                        <li>Launch our discord</li>
                        <li>Launch our website</li>
                        <li>Launch our twitter</li>
                        <li>Conduct Pregame Giveaways</li>
                        
                    </ul>
                </div>
                <div class="public-kickoff t-camp">
                    <h5>15% public kickoff</h5>
                    <ul>
                        <li>The remaining GOATS are then born and minting officially begins on our website!</li>
                        <li>Each GOAT receives access to our private Discord channel with expert sports betting research, daily picks, and discussion</li>
                        <li>Each GOAT receives access to our weekly one hour VIP video lounge with developers and sports guests. Only GOATS can enter. We talk sports, and provide networking with fellow GOATS and real life professional athletes.</li>
                    </ul>
                </div>
                <div class="game-begin t-camp">
                    <h5>35% let the games begin!</h5>
                    <ul>
                        <li>Our weekly pick’ em contest begins on Discord to determine who’s the weekly GOAT of sports picks!</li>
                        <li>Every single week of the next year, all GOATS Holders will receive free entry into our private pick 'em' contest where you’ll predict the winners of 10 sports games taking place that week. Each week, the top 3 winners will receive ETH prizes!</li>
                        <li>Each weekly winner, over the course of the next year, will be entered into a raffle. The yearly winner who's drawn will win our ETH Trophy! (Worth 1 ETH)</li>
                    </ul>
                </div>
                <div class="donation t-camp">
                    <h5>65% charitable donation</h5>
                    <ul>
                        <li>It’s time to gameplan and get better. We will be donating $20,000 to the Make A Wish foundation, allocated specifically to sports experiences.</li>
                    </ul>
                </div>
                <div class="poppin-champagne t-camp">
                    <h5>100% poppin' champagne</h5>
                    <ul>
                        <li>We are champions and it’s time to party! A real life GOAT meet up will be coordinated in Las Vegas, the epicenter of sports betting.</li>
                        <li>Additionally, every GOAT will be airdropped an exclusive new championship ring NFT only available to GOAT holders.</li>
                        <li>We will be working to develop a sports lounge in the metaverse where your legendary GOAT will live forever.</li>
                    </ul>
                </div>
                </div>
            <div class="box-2"><img src={RoadMapImg} /></div>
            <div class="box-3">
                <div class="t-camp2 t-camp">
                    <h5>0% Pregame Sale</h5>
                    <ul>
                        <li>350 of our GOATS will be available for a pre-sale mint. (0.11 ETH for presale and 0.15 ETH for the public)</li>
                        <li>50 Golden Goats will be reserved exclusively for this presale. You’ll have great odds to get one!</li>
                    </ul>
                </div>
                <div class="real-mvp t-camp">
                    <h5>23% You The Real MVP : Rolex Giveaway</h5>
                    <ul>
                        <li>Afterall, 23 is the ultimate GOAT number! That means it’s time to gift one of our GOATs a prestigious status symbol. If you minted 1 of our first 534 GOATS (23%), we will randomly select one GOAT to receive a Rolex Submariner watch, valued at $15,000.</li>
                    </ul>
                </div>
                <div class="halftime-festivities t-camp">
                    <h5>50% Halftime Festivities</h5>
                    <ul>
                        <li>Halftime means it’s time to catch a breather and grab some air… in the form of an AirDrop! We will select our 50 hardest working GOATS (most active on discord) who each will receive 0.1 ETH AirDrop.</li>
                        <li>This essentially means you’ll be replenished! We’ll send you 0.1 ETH which will be nearly the amount you initially minted a GOAT for.</li>
                        <li>Our merchandise store also opens at halftime where all proceeds will be put into a community marketing fund for future projects and more giveaways.</li>
                        </ul>
                </div>
                <div class="fourth-quarter t-camp">
                    <h5>75% Fourth Quarter</h5>
                    <ul>
                        <li>You’ve made it to the fourth quarter, where GOATS create their own legacy.</li>
                        <li>We will select 5 GOATS to work with our VIP Concierge. We will send you on your ultimate sports vacation. Whether it’s attending the biggest football game of the year, or playing a round of golf at your dream course, we will do our best to turn your dream sports trip into reality.</li>
                        </ul>
                </div>
            </div>
       </div>
       <div class="road-map-mobile">
            <div class="box-4">
                <div class="t-camp">
                    <h5>Training Camp</h5>
                    <ul>
                        <li>Launch our discord</li>
                        <li>Launch our website</li>
                        <li>Launch our twitter</li>
                        <li>Conduct Pregame Giveaways</li>
                        
                    </ul>
                </div>
                
                <div class="t-camp2 t-camp">
                    <h5>0% Pregame Sale</h5>
                    <ul>
                        <li>350 of our GOATS will be available for a pre-sale mint. (0.11 ETH for presale and 0.15 ETH for the public)</li>
                        <li>50 Golden Goats will be reserved exclusively for this presale. You’ll have great odds to get one!</li>
                    </ul>
                </div>
                
                <div class="public-kickoff t-camp">
                    <h5>15% public kickoff</h5>
                    <ul>
                        <li>The remaining GOATS are then born and minting officially begins on our website!</li>
                        <li>Each GOAT receives access to our private Discord channel with expert sports betting research, daily picks, and discussion</li>
                        <li>Each GOAT receives access to our weekly one hour VIP video lounge with developers and sports guests. Only GOATS can enter. We talk sports, and provide networking with fellow GOATS and real life professional athletes.</li>
                    </ul>
                </div>
                
                <div class="real-mvp t-camp">
                    <h5>23% You The Real MVP : Rolex Giveaway</h5>
                    <ul>
                        <li>Afterall, 23 is the ultimate GOAT number! That means it’s time to gift one of our GOATs a prestigious status symbol. If you minted 1 of our first 534 GOATS (23%), we will randomly select one GOAT to receive a Rolex Submariner watch, valued at $15,000.</li>
                    </ul>
                </div>
                
                <div class="game-begin t-camp">
                    <h5>35% let the games begin!</h5>
                    <ul>
                        <li>Our weekly pick’ em contest begins on Discord to determine who’s the weekly GOAT of sports picks!</li>
                        <li>Every single week of the next year, all GOATS Holders will receive free entry into our private pick 'em' contest where you’ll predict the winners of 10 sports games taking place that week. Each week, the top 3 winners will receive ETH prizes!</li>
                        <li>Each weekly winner, over the course of the next year, will be entered into a raffle. The yearly winner who's drawn will win our ETH Trophy! (Worth 1 ETH)</li>
                    </ul>
                </div>
                
                 <div class="halftime-festivities t-camp">
                    <h5>50% Halftime Festivities</h5>
                    <ul>
                        <li>Halftime means it’s time to catch a breather and grab some air… in the form of an AirDrop! We will select our 50 hardest working GOATS (most active on discord) who each will receive 0.1 ETH AirDrop.</li>
                        <li>This essentially means you’ll be replenished! We’ll send you 0.1 ETH which will be nearly the amount you initially minted a GOAT for.</li>
                        <li>Our merchandise store also opens at halftime where all proceeds will be put into a community marketing fund for future projects and more giveaways.</li>
                        </ul>
                </div>
                
                <div class="donation t-camp">
                    <h5>65% charitable donation</h5>
                    <ul>
                        <li>It’s time to gameplan and get better. We will be donating $20,000 to the Make A Wish foundation, allocated specifically to sports experiences.</li>
                    </ul>
                </div>
                
                <div class="fourth-quarter t-camp">
                    <h5>75% Fourth Quarter</h5>
                    <ul>
                        <li>You’ve made it to the fourth quarter, where GOATS create their own legacy.</li>
                        <li>We will select 5 GOATS to work with our VIP Concierge. We will send you on your ultimate sports vacation. Whether it’s attending the biggest football game of the year, or playing a round of golf at your dream course, we will do our best to turn your dream sports trip into reality.</li>
                        </ul>
                </div>
                
                <div class="poppin-champagne t-camp">
                    <h5>100% poppin' champagne</h5>
                    <ul>
                        <li>We are champions and it’s time to party! A real life GOAT meet up will be coordinated in Las Vegas, the epicenter of sports betting.</li>
                        <li>Additionally, every GOAT will be airdropped an exclusive new championship ring NFT only available to GOAT holders.</li>
                        <li>We will be working to develop a sports lounge in the metaverse where your legendary GOAT will live forever.</li>
                    </ul>
                </div>
            </div>
       </div>
    </div>
</section> 
        </div>
    )
}
