// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from '@firebase/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDS9MhbJVRSeVhtrUsc8ZqRFrKACWTqqbo",
  authDomain: "egoldengoats.firebaseapp.com",
  databaseURL: "https://egoldengoats-default-rtdb.firebaseio.com",
  projectId: "egoldengoats",
  storageBucket: "egoldengoats.appspot.com",
  messagingSenderId: "956511513765",
  appId: "1:956511513765:web:08841015671779a297714c",
  measurementId: "G-V7QE6J38DY"
};

//Testing Creds
  // const firebaseConfig = {
  //   apiKey: "AIzaSyBUhA7KiarT5IACGSADue2VnP3GdkDnYmg",
  //   authDomain: "eglobalgoatsstag.firebaseapp.com",
  //   projectId: "eglobalgoatsstag",
  //   storageBucket: "eglobalgoatsstag.appspot.com",
  //   messagingSenderId: "1085031777881",
  //   appId: "1:1085031777881:web:08bb0fc9f6f28a1c791348",
  //   measurementId: "G-D05DET3W05"
  //   };


// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);