import React,{useEffect,useState} from 'react'
import './AdminCss.css'
import logo from '../../assets/images/logo.png'

export default function Sidebar() {
	const[dashboardBackground,setdashboardBackground]=useState('#FB0000');
	const [manageNftbg,setmanageNftbg]=useState("");
	const [giveAwaybg,setgiveAwaybg]=useState("");

    useEffect(()=>{
        const pathname = window.location.pathname 
        console.log("pathname is",pathname)
		  if(pathname=="/AdminDashboard"){
			setdashboardBackground('#FB0000');
			setmanageNftbg("")
			setgiveAwaybg("")
		  }
		  else if(pathname=='/EditNFT/'){
			setdashboardBackground('');
			setmanageNftbg("#FB0000")
			setgiveAwaybg("")
		  }
		  else if(pathname=='/giveAways'){
			setdashboardBackground('');
			setmanageNftbg("")
			setgiveAwaybg("#FB0000")
		  }
     
    },[])
	
    return (
       
				<div class="left_bar_text">

                <a href="/AdminDashboard/"><img src={logo}/></a>

					<ul class="bar_item">
						   <li style={{backgroundColor:dashboardBackground,}}><a href="/AdminDashboard"><i class="fa fa-tachometer" aria-hidden="true"></i>Dashboard</a></li>
						   <li style={{backgroundColor:manageNftbg,color:'black'}} ><a href="/EditNFT/"><i class="fa fa-pencil-square-o" aria-hidden="true"></i>Manage NFT'S</a></li>
						   <li style={{backgroundColor:giveAwaybg,color:'black'}} ><a href="/giveAways"><i class="fa fa-cog" aria-hidden="true"></i>Give Aways</a></li>
					</ul>
				</div>
		
        
    )
}
