import React, { useState, useEffect, useContext, useReducer } from 'react';
import '../ResponsiveCss/Responsive.css';
import { useEthers } from "@usedapp/core";
import { Link,NavLink,useLocation } from 'react-router-dom';

// import logo from '../../assets/images/logoImg.png';
import menu from '../../assets/images/menu.png';
import Identicon from '../Identicon';
import CrudMethods from '../../config/CrudMethods';
import { ropstenBaseURL, baseURL, ETHER_SCAN_API_KEY } from '../../constants/global';
import Web3 from 'web3';
import { gettokens } from '../../config/ApisController';
import { ReactComponent as TWL } from '../../assets/images/TwitterWhiteLogo.svg';
import face from '../../assets/images/discord.png';
import { doc, getDoc } from "firebase/firestore";
import { db } from "./../../config/firebase-config";
import logo from '../../assets/images/logo.png';
import twitter from '../../assets/images/twitter.png';
import discord from '../../assets/images/discord.png';
import openseaLogoOn from '../../assets/images/opensealogobig.png';
import openseaLogo from '../../assets/images/opensealogobig-disabled.png';
import Modal from 'react-modal';
import InfoImg from '../../assets/images/info.jpeg';


const Navbar = ({ height, width }) => {

    const [showMenuDiv, setShowMenuDiv] = useState(width < 769 ? false : true);
    const { activateBrowserWallet, account, deactivate } = useEthers();
    const [getBalance, setBalance] = useState('0');
    const [getLogin, setLogin] = useState({});
    const [nftERC721, setNftERC721] = useState('');
    const [page, setPage] = useState(1);
    var [metaData, setMetaData] = useState([]);
    var [metaDataDefault, setMetaDataDefault] = useState('');
    var [metaData1, setMetaData1] = useState([]);
    const [navigatorDynamics, setNavigatorDynamics] = useState([]);
    const [userDetails, setUserDetails] = useState([]);

    const [modalIsOpen, setIsOpen] = React.useState(false);


    const [mynftColor, setmynftColor] = useState('white');
    const [exploreColor, setexploreColor] = useState('white');
    const [mintColor, setmintColor] = useState('white');
    const [aboutColor, setaboutColor] = useState('white');
    const [roadmapColor, setroadmapColor] = useState('white');
    const [ourTeamColor, setouTeamColor] = useState('white');
    const [faqColor, setfaqColor] = useState('white');

    const ethereum = window.ethereum;
    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
      },
    }
    const search = useLocation().search;
    const test = new URLSearchParams(search).get('test');
    console.log("name is1123",test)
    // {test=="on"?"":Explore}
    useEffect(()=>{
        const pathname = window.location.pathname 
        console.log("pathname is",pathname)
        if(pathname=="/MyNft"){
            setmynftColor("yellow")
        setexploreColor("white")
        setfaqColor("white")
        setmintColor("white")
        setaboutColor("white")
        setroadmapColor("white")
        setouTeamColor("white")

        }
        else if(pathname=="/BuyNft"){
            setmynftColor("white")
            setexploreColor("yellow")
            setfaqColor("white")
            setmintColor("white")
            setaboutColor("white")
            setroadmapColor("white")
            setouTeamColor("white")
        }
       else{

       }
    },[])


    useEffect(() => {
        {ethereum && ethereum.isMetaMask ? ChainId():null }
        {ethereum && ethereum.isMetaMask ? onNetworkChange():null }

    },[]);

    
    const switchNetwork=async()=>{
      closeModal()
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x1' }], // chainId must be in hexadecimal numbers
      });
    }
    
    const ChainId=async()=>{
      const  web3=new Web3(Web3.givenProvider)
      console.log("we3 is",web3);
      // const chainid=await new web3.eth.net.getId()
      const chainid=await new web3.eth.getChainId()
      console.log("Chain ID====",chainid)
      if(chainid == 4 || chainid == 2 || chainid == 3 || chainid == 5){
        openModal();
      }
      
    }
  
    const onNetworkChange=()=>{
       ethereum.on('chainChanged', (chainId) => {
        console.log("Chain Idd is",chainId)
        if(chainId == 0x4 || chainId == 0x2 || chainId == 0x3 || chainId == 0x5){
          openModal();
        }
      });
    }
    function openModal(value) {
      setIsOpen(true);
     
    }
  
    function afterOpenModal() {
      // references are now sync'd and can be accessed.
      
    }
  
    function closeModal() {
      setIsOpen(false);
    }


    const myStorage = window.localStorage;
    useEffect(() => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log('Height of the Page ' + height, '  Width of the Page  ' + width);
            console.log('Login Data ', JSON.parse(myStorage.getItem("loginsession")));
        }
    }, [height, width]);

    useEffect(async() => {
        if (ethereum && ethereum.isMetaMask) {
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                console.log('MetaMask is Installed')
            }
        } else {
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                console.log('MetaMask is not Installed')
            }
        }

        const json = myStorage.getItem("loginsession");
        const savedLoginSession = JSON.parse(json);
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log('Login Info: ', savedLoginSession);
            console.log('djkgksg  ', account)
        }
        if (savedLoginSession) {
            setLogin(savedLoginSession);
            var getCustomAddress = savedLoginSession.wallet_id;
            var setData = getCustomAddress;
            if (setData == null) {
                if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                    console.log('Local Data is Null or Undefined', setData);
                }
            }
            else {
                //getNFT(setData);
            }
        } else {
            setNftERC721([])
        }
    }, []);

    useEffect(async() => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log('Account Chnaged handler ', JSON.stringify(account));
        }
        if(account==undefined || account.length==0){
            myStorage.clear(); setLogin({});
        }
        console.log("account ======== ", account)
        getUserDetail(account);
        handleWalletChange();
    }, [account]);

    const getUserDetail = async(data) => {
        if (data) {
            const docRef = doc(db, "Users", (data.toLowerCase()));
            const docSnap = await getDoc(docRef);
    
            if (docSnap.exists()) {
                console.log("Document data:", docSnap.data());
                setUserDetails(docSnap.data())
            }
        }
    }

    const handleWalletChange = async() => {
        if (ethereum) {
            console.log("Is handle wallet change called")
            ethereum.on('accountsChanged', function (accounts) {
                console.log("accounts are",accounts)

                if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                    console.log('First Account', accounts[0]);
                }

                if (accounts.length > 0) {
                                  
                                console.log("What is in accounts[0]",accounts[0]);
                                 var address=accounts[0]
                                 console.log("address is21",address)
                                 getDoc(doc(db,"Users",address)).then(docSnap => {
                                    if (docSnap.exists()) {
                                      console.log("Document data:", docSnap.data());
                                      window.location.reload();

                                    } else {
                                      console.log("No such document!>>");
                                      const RegisterData = {
                                            address: '' + accounts[0],
                                            name: '',
                                            IsAdmin:false
                                        };
                                        CrudMethods.RegisterUser("Users", RegisterData, accounts[0]);
                                        // window.location.reload()
                                    }
                                  })
                }
            });

        }
    }

    // Connect Wallet Method
    const handleConnectWallet = () => {
        login();
    }

    const getNFT = async (setData) => {
        try {
            const params = {
                action: "tokennfttx",
                address: setData,
                page: page,
                module: "account",
                sort: "desc",
                offset: 30,
                apikey: ETHER_SCAN_API_KEY
            };

            const response = await gettokens(params);
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                console.log("gettokens api response", response.result);
            }
            setNftERC721([...nftERC721, ...response.result]);
            var data = response.result;
            for (var i = 0; i < data.length; i++) {
                if (data[i].tokenID.length > 7) {
                    // console.log("Invalid token")
                }
                else {

                    var contractAddress = data[i].contractAddress;
                    var tokenId = data[i].tokenID
                    //setLoading(true)
                    await getMeta(contractAddress, tokenId)
                    // var param = {
                    //     contractAddress: data[i].contractAddress,
                    //     tokenId: data[i].tokenID,
                    // };
                }
            }
            //setLoading(false);
        } catch (error) {
            console.log("gettokens err", error)
        }
    }

    const getMeta = async (contractAddress, tokenId) => {
        const ERC20TransferABI = [
            {
                "inputs": [

                ],
                "stateMutability": "nonpayable",
                "type": "constructor"
            },
            {
                "anonymous": false,
                "inputs":
                    [
                        {
                            "indexed": true,
                            "internalType": "address",
                            "name": "owner",
                            "type": "address"
                        },
                        {
                            "indexed": true,
                            "internalType": "address",
                            "name": "approved",
                            "type": "address"
                        },
                        {
                            "indexed": true,
                            "internalType": "uint256",
                            "name": "tokenId",
                            "type": "uint256"
                        }
                    ],
                "name": "Approval",
                "type": "event"
            },
            {
                "anonymous": false,
                "inputs":
                    [
                        {
                            "indexed": true,
                            "internalType": "address",
                            "name": "owner",
                            "type": "address"
                        },
                        {
                            "indexed": true,
                            "internalType": "address",
                            "name": "operator",
                            "type": "address"
                        },
                        {
                            "indexed": false,
                            "internalType": "bool",
                            "name": "approved",
                            "type": "bool"
                        }
                    ],
                "name": "ApprovalForAll",
                "type": "event"
            },
            {
                "anonymous": false,
                "inputs":
                    [
                        {
                            "indexed": true,
                            "internalType": "address",
                            "name": "previousOwner",
                            "type": "address"
                        },
                        {
                            "indexed": true,
                            "internalType": "address",
                            "name": "newOwner",
                            "type": "address"
                        }
                    ],
                "name": "OwnershipTransferred",
                "type": "event"
            },
            {
                "anonymous": false,
                "inputs":
                    [
                        {
                            "indexed": true,
                            "internalType": "address",
                            "name": "from",
                            "type": "address"
                        },
                        {
                            "indexed": true,
                            "internalType": "address",
                            "name": "to",
                            "type": "address"
                        },
                        {
                            "indexed": true,
                            "internalType": "uint256",
                            "name": "tokenId",
                            "type": "uint256"
                        }
                    ],
                "name": "Transfer",
                "type": "event"
            },
            {
                "anonymous": false,
                "inputs":
                    [
                        {
                            "indexed": false,
                            "internalType": "uint256",
                            "name": "msgValue",
                            "type": "uint256"
                        },
                        {
                            "indexed": false,
                            "internalType": "uint256",
                            "name": "amount",
                            "type": "uint256"
                        },
                        {
                            "indexed": false,
                            "internalType": "uint256",
                            "name": "convertedvalue",
                            "type": "uint256"
                        }
                    ],
                "name": "VoteCast",
                "type": "event"
            },
            {
                "inputs":
                    [
                        {
                            "internalType": "uint256",
                            "name": "tokenId",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "tokenPrice",
                            "type": "uint256"
                        }
                    ],
                "name": "_setTokenPrice",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs":
                    [
                        {
                            "internalType": "address",
                            "name": "to",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "tokenId",
                            "type": "uint256"
                        }
                    ],
                "name": "approve",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs":
                    [
                        {
                            "internalType": "address",
                            "name": "owner",
                            "type": "address"
                        }
                    ],
                "name": "balanceOf",
                "outputs":
                    [
                        {
                            "internalType": "uint256",
                            "name": "",
                            "type": "uint256"
                        }
                    ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs":
                    [
                        {
                            "internalType": "uint256",
                            "name": "tokenId",
                            "type": "uint256"
                        }
                    ],
                "name": "buy",
                "outputs": [],
                "stateMutability": "payable",
                "type": "function"
            },
            {
                "inputs":
                    [
                        {
                            "internalType": "uint256",
                            "name": "tokenId",
                            "type": "uint256"
                        }
                    ],
                "name": "getApproved",
                "outputs":
                    [
                        {
                            "internalType": "address",
                            "name": "",
                            "type": "address"
                        }
                    ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs":
                    [
                        {
                            "internalType": "uint256",
                            "name": "tokenId",
                            "type": "uint256"
                        }
                    ],
                "name": "getCurrentOwner",
                "outputs":
                    [
                        {
                            "internalType": "address",
                            "name": "",
                            "type": "address"
                        }
                    ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "uint256",
                        "name": "tokenId",
                        "type": "uint256"
                    }
                ],
                "name": "getCurrentPriceOfToken",
                "outputs":
                    [
                        {
                            "internalType": "uint256",
                            "name": "",
                            "type": "uint256"
                        }
                    ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs":
                    [
                        {
                            "internalType": "uint256",
                            "name": "tokenId",
                            "type": "uint256"
                        }
                    ],
                "name": "getFirstOwner",
                "outputs":
                    [
                        {
                            "internalType": "address",
                            "name": "", "type": "address"
                        }
                    ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs":
                    [
                        {
                            "internalType": "address",
                            "name": "owner",
                            "type": "address"
                        },
                        {
                            "internalType": "address",
                            "name": "operator",
                            "type": "address"
                        }
                    ],
                "name": "isApprovedForAll",
                "outputs":
                    [
                        {
                            "internalType": "bool",
                            "name": "",
                            "type": "bool"
                        }
                    ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs":
                    [
                        {
                            "internalType": "address",
                            "name": "_to",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "_tokenId",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "_TokenPrice",
                            "type": "uint256"
                        },
                        {
                            "internalType": "string",
                            "name": "tokenURI_",
                            "type": "string"
                        }
                    ],
                "name": "mint",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "name",
                "outputs":
                    [
                        {
                            "internalType": "string",
                            "name": "",
                            "type": "string"
                        }
                    ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "owner",
                "outputs":
                    [
                        {
                            "internalType": "address",
                            "name": "",
                            "type": "address"
                        }
                    ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs":
                    [
                        {
                            "internalType": "uint256",
                            "name": "tokenId",
                            "type": "uint256"
                        }
                    ],
                "name": "ownerOf",
                "outputs":
                    [
                        {
                            "internalType": "address",
                            "name": "",
                            "type": "address"
                        }
                    ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "renounceOwnership",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs":
                    [
                        {
                            "internalType": "address",
                            "name": "from",
                            "type": "address"
                        },
                        {
                            "internalType": "address",
                            "name": "to",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "tokenId",
                            "type": "uint256"
                        }
                    ],
                "name": "safeTransferFrom", 
                "outputs": [], 
                "stateMutability": "nonpayable", 
                "type": "function"
            }, 
            { 
                "inputs": 
                [
                    { 
                        "internalType": "address", 
                        "name": "from", 
                        "type": "address" 
                    }, 
                    { 
                        "internalType": "address", 
                        "name": "to", 
                        "type": "address" 
                    }, 
                    { 
                        "internalType": "uint256", 
                        "name": "tokenId", 
                        "type": "uint256" 
                    }, 
                    { 
                        "internalType": "bytes", 
                        "name": "_data", 
                        "type": "bytes" 
                    }
                ], 
                "name": "safeTransferFrom", 
                "outputs": [], 
                "stateMutability": "nonpayable", 
                "type": "function" 
            }, 
            { 
                "inputs": 
                [
                    { 
                        "internalType": "address", 
                        "name": "operator", 
                        "type": "address" 
                    }, 
                    { 
                        "internalType": "bool", 
                        "name": "approved", 
                        "type": "bool" 
                    }
                ], 
                "name": "setApprovalForAll", 
                "outputs": [], 
                "stateMutability": "nonpayable",
                "type": "function" 
            }, 
            { 
                "inputs": 
                [
                    { 
                        "internalType": "string", 
                        "name": "baseURI_", 
                        "type": "string" 
                    }
                ], 
                "name": "setBaseURI", 
                "outputs": [], 
                "stateMutability": "nonpayable", 
                "type": "function" 
            }, 
            { 
                "inputs": 
                [
                    { 
                        "internalType": "bytes4", 
                        "name": "interfaceId", 
                        "type": "bytes4" 
                    }
                ], 
                "name": "supportsInterface", 
                "outputs": 
                [
                    { 
                        "internalType": "bool", 
                        "name": "", 
                        "type": "bool" 
                    }
                ], 
                "stateMutability": "view", 
                "type": "function" 
            }, 
            { 
                "inputs": [], 
                "name": "symbol", 
                "outputs": 
                [
                    { 
                        "internalType": "string", 
                        "name": "", 
                        "type": "string" 
                    }
                ], 
                "stateMutability": "view", 
                "type": "function" 
            }, 
            { 
                "inputs": 
                [
                    { 
                        "internalType": "uint256", 
                        "name": "tokenId", 
                        "type": "uint256" 
                    }
                ], 
                "name": "tokenURI", 
                "outputs": 
                [
                    { 
                        "internalType": "string", 
                        "name": "", 
                        "type": "string" 
                    }
                ], 
                "stateMutability": "view", 
                "type": "function" 
            }, 
            { 
                "inputs": 
                [
                    { 
                        "internalType": "address", 
                        "name": "from", 
                        "type": "address" 
                    }, 
                    { 
                        "internalType": "address", 
                        "name": "to", 
                        "type": "address" 
                    }, 
                    { 
                        "internalType": "uint256", 
                        "name": "tokenId", 
                        "type": "uint256" 
                    }
                ], 
                "name": "transferFrom", 
                "outputs": [], 
                "stateMutability": "nonpayable", 
                "type": "function" 
            }, 
            { 
                "inputs": 
                [
                    { 
                        "internalType": "address", 
                        "name": "newOwner", 
                        "type": "address" 
                    }
                ], 
                "name": "transferOwnership", 
                "outputs": [], 
                "stateMutability": "nonpayable", 
                "type": "function" 
            }
        ]

        const NFT_ADDRESS = contractAddress
        const web3 = new Web3(Web3.givenProvider || "https://mainnet.infura.io/v3/c4a896a1ff0e489fb4f730d8908d16b2")
        const daiToken = new web3.eth.Contract(ERC20TransferABI, NFT_ADDRESS)
        //console.log("daiTooken is",daiToken.methods) //  .mint Function
        daiToken.methods.mint().call(async function (err, res) {
            if (err) {
                console.log("An error occured", err)
            }
            if (res.includes('1pfs:')) {
                console.log("ipfs url", res)
            }
        })
        // daiToken.mint.tokenURI(tokenId).call(async function (err, res) {
        //     if (err) {
        //         console.log("An error occured", err)
        //         return
        //     }
        //     if (res.includes('ipfs:')) {
        //         console.log("ipfs url", res)
        //     }
        //     else {
        //         console.log("getMeta ", res)
        //         await geMetaData(res)
        //     }

        // })
    }

    const geMetaData = async (res) => {
        await fetch(res, { mode: 'no-cors' })
            .then((response) => response.json())
            .then((json) => {
                //setLoading(true)
                // console.log(json);
                var data = metaData;
                var aaa1 = [];
                var aaa = metaData;
                aaa.push(json);
                aaa1.push(json);
                setMetaData(aaa)
                setMetaDataDefault(aaa)
                setMetaData1([...data, ...aaa1]);
                console.log("metaData state is", metaData)
            });

    }

    const login=async()=>{
        const permissions = await ethereum.request({
    method: 'wallet_requestPermissions',
    params: [{
      eth_accounts: {},
    }]
  });
  await activateBrowserWallet();
  //console.log('Value of Account at connect Wallet Time  ', account);
  await handleWalletChange();
    }
  const NavbarStyle=(value)=>{
    if(value==1){
        setmynftColor("yellow")
        setexploreColor("white")
        setfaqColor("white")
        setmintColor("white")
        setaboutColor("white")
        setroadmapColor("white")
        setouTeamColor("white")
        console.log("mynfts")
    }
    else if(value==2){
        console.log("explore")
        setmynftColor("white")
        setexploreColor("yellow")
        setfaqColor("white")
        setmintColor("white")
        setaboutColor("white")
        setroadmapColor("white")
        setouTeamColor("white")
    }
    else{

    }

}

    return (
        <div class="header">
        <div class="logo"><a href="/"><img src={logo}/></a></div>  
        <nav class="navigation-menu">
            <ul class="nav_main_list">
            <li  onClick={()=>{NavbarStyle(1)}}><NavLink style={{color:mynftColor}} to="/MyNft">MY NFT's</NavLink></li>
                <li onClick={()=>{NavbarStyle(2)}}><NavLink style={{color:exploreColor}} to="/BuyNft">Explore</NavLink></li>
                <li ><a  href="/#mint">Mint</a></li>
                <li ><a  href="/#about">About</a></li>
                <li ><a  href="/#roadmap">RoadMap</a></li>
                <li ><a href="/#team">Our Team</a></li>
                <li ><a  href="/#faq">FAQ</a></li>
                <li><a href="https://twitter.com/GlobalGoatsNFT" target="_blank"><img src={twitter}/></a></li>
                <li><a href="https://t.co/AiSfFiJX3j" target="_blank"><img src={discord}/></a></li>
                <li><a href="https://opensea.io/collection/globalgoatsnft" target="_blank"><img class="opensea" src={openseaLogoOn}/></a></li>

               
                

           
                          {account && (Object.keys(getLogin).length > 0 || getLogin != null) ?
                   
                            <li className={"navbar-wallet-info"} onClick={() => { deactivate(); myStorage.clear(); setLogin({}); }}>
                                <a href="#" class="join-button"> 
                            Disconnect</a>
                            <span class="join-button-bottom">
                               {account &&
                                    `${account.slice(0, 6)}...${account.slice(
                                        account.length - 4,
                                        account.length
                                    )}`}
                                    </span>
                                    </li>
                                                      
                        :
                        {
                            ...ethereum && ethereum.isMetaMask ?
                            
                            // <div className={"btn-connect"} onClick={() => { handleConnectWallet() }}>
                                <div className={"btn-connect"} onClick={() => {handleConnectWallet()}}>
                                     <li><a href="#" class="join-button">Connect</a></li>
                                </div>
                                :
                                <div className={"btn-error"} >
                                    <span style={{color:'white'}} className={"btn-error-txt"}>
                                        MetaMask not installed
                                    </span>
                                </div>
                        }
                    }

                
            </ul> 
        </nav>
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            shouldCloseOnOverlayClick={false}
          >
            <div class="infotext_Modal">
            <img class="infoImg" src={InfoImg}/>
            <h4>Wrong Network!</h4>
            <p>Please change it to MainNet Network!</p>
            <button class="infobtnModal" onClick={()=>{switchNetwork()}}>ok</button>
            </div>
            </Modal>
        <div class="mobile-nft">
            <NavLink to="/MyNft">MY NFT's</NavLink> &nbsp;| &nbsp;
            <NavLink to="/BuyNft">Explore</NavLink>
        </div>
</div>
    );
};

export default Navbar;

