import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useEthers } from "@usedapp/core";
import { doc, getDocs, getDoc, collection, setDoc, updateDoc } from "firebase/firestore";
import Modal from 'react-modal';
import Web3 from 'web3';
import Footer from '../../components/Footer/Footer.js';
import Navbar from '../../components/NavBar/Navbar.js';
import CrudMethods from '../../config/CrudMethods';
import '../ResponsiveCss/Responsive.css';
import { db } from "./../../config/firebase-config";
import Accordian from '../../components/Accordian/Accordian.js';
import logo from '../../assets/images/logo.png';
import twitter from '../../assets/images/twitter.png';
import bannertitle from '../../assets/images/banner-title.png';
import bannerImage from '../../assets/images/banner-image.png';
import Mint from '../MintSection/Mint.js';
import About from '../About/About.js';
import Grass from '../Grass/Grass.js';
import OurCollection from '../OurCollections/OurCollection.js';
import RoadMap from '../RoadMap/RoadMap.js';
import GrassBlue from '../GrassBlue/GrassBlue.js';
import InfoImg from '../../assets/images/info.jpeg';
import CountDown from '../CountDown/CountDown.js';
import OurTeam from '../OurTeam/OurTeam.js';
const LandingPage = () => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const { activateBrowserWallet, account, deactivate } = useEthers();



  const ethereum = window.ethereum;
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  }

  const switchNetwork=async()=>{
    closeModal()
    await ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: '0x4' }], // chainId must be in hexadecimal numbers
    });
  }
  
  const ChainId=async()=>{
    const  web3=new Web3(Web3.givenProvider)
    console.log("we3 is",web3);
    // const chainid=await new web3.eth.net.getId()
    const chainid=await new web3.eth.getChainId()
    console.log("Chain ID====",chainid)
    if(chainid == 1 || chainid == 2 || chainid == 3 || chainid == 5){
      openModal();
    }
    
  }

  const onNetworkChange=()=>{
     ethereum.on('chainChanged', (chainId) => {
      console.log("Chain Idd is",chainId)
      if(chainId == 0x1 || chainId == 0x2 || chainId == 0x3 || chainId == 0x5){
        openModal();
      }
    });
  }
  function openModal(value) {
    setIsOpen(true);
   
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    
  }

  function closeModal() {
    setIsOpen(false);
  }


  // useEffect(() => { 
  //   ChainId();
  //   onNetworkChange();
  // },[]);
  

  function GetSortOrder(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    }
  }


  return (
    <div>
      <section class="first-section" id="home">
        <div class="container">
          <Navbar />
        
          <div class="banner-text">
            <div class="banner-title">
              <img src={bannertitle} />
              <p>In sports, the word GOAT stands for the Greatest Of All Time. We are an exclusive club of 2323 GOATS who Represent the biggest sports fans in the world. If you want to achieve GOAT status, this community is for you.</p>
            </div>
            <div class="banner-image">
              <img src={bannerImage} />
            </div>
          </div>
        </div>
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            shouldCloseOnOverlayClick={false}
          >
            <div class="infotext_Modal">
            <img class="infoImg" src={InfoImg}/>
            <h4>Wrong Network!</h4>
            <p>Please change it to Rinkeby TestNet Network!</p>
            <button class="infobtnModal" onClick={()=>{switchNetwork()}}>ok</button>
            </div>
            </Modal>
      </section>
      <Mint />
      <About />
      <Grass />
      <OurCollection />
      <RoadMap />
      <GrassBlue />
      <OurTeam/>
      <Accordian />
      <Footer />
    </div>
  );
};

export default LandingPage;