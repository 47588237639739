import React from 'react'
import Footer from '../Footer/Footer.js'
import Navbar from '../NavBar/Navbar.js'
import '../ResponsiveCss/Responsive.css'
export default function TermsofSale() {
    return (
        <div>
        <div class="first-section">
          <div class="container">
            <Navbar/>
          </div>
        </div>
        <section class="third-section">
          <div class="container">
            <h2>Terms of Use</h2>
              <p>These Terms of Use, your rights and obligations, and all actions contemplated by, arising out of or related to these Terms shall be governed by the laws of the State of Texas, as if these Terms are a contract wholly entered into and wholly performed within the State of Texas.</p>   
              <p>YOU UNDERSTAND AND AGREE THAT YOUR USE OF THIS SITE AS CONTEMPLATED BY THESE TERMS SHALL BE DEEMED TO HAVE OCCURRED IN THE STATE OF TEXAS AND BE SUBJECT TO THE INTERNAL LAWS OF THE STATE OF TEXAS WITHOUT REGARD TO ITS CONFLICTS OF LAWS PROVISIONS.
</p>
            <h6>Disputes and Arbitration Agreement</h6>
            <p>Carefully read the following arbitration agreement ("Arbitration Agreement"). It requires you to arbitrate disputes with Global Goats, LLC and limits the manner in which you can seek relief from us.
</p>
<h6>Applicability of Arbitration Agreement</h6>
<p>You agree that any dispute or claim relating in any way to your access or use of the Site, to any Services sold or distributed through the Site, including NFTs, or to any aspect of your relationship with Global Goats, LLC will be resolved by binding arbitration, rather than in court, except that (1) you may assert claims in small claims court if your claims qualify; and (2) you or Global Goats may seek equitable relief in court for infringement or other misuse of intellectual property rights (such as trademarks, trade dress, domain names, trade secrets, copyrights, and patents).
</p>
<h6>Arbitration Rules and Forum</h6>
<p>The Federal Arbitration Act governs the interpretation and enforcement of this Arbitration Agreement. To begin an arbitration proceeding, you must send a letter requesting arbitration and describing your claim to our registered agent Corporation Service Company, 251 Little Falls Drive, Wilmington Delaware 19808. The arbitration will be conducted by JAMS, an established alternative dispute resolution provider. Disputes involving claims and counterclaims under $250,000, not inclusive of attorneys’ fees and interest, shall be subject to JAMS’s most current version of the Streamlined Arbitration Rules and procedures available at http://www.jamsadr.com/rules-streamlined-arbitration/; all other claims shall be subject to JAMS’s most current version of the Comprehensive Arbitration Rules and Procedures, available at http://www.jamsadr.com/rules-comprehensive-arbitration/. JAMS’s rules are also available at jamsadr.com or by calling JAMS at 800-352-5267. If JAMS is not available to arbitrate, the parties will select an alternative arbitral forum. If the arbitrator finds that you cannot afford to pay JAMS’s filing, administrative, hearing and/or other fees and cannot obtain a waiver from JAMS, Global Goats will pay them for you. You may choose to have the arbitration conducted by telephone or video conference or based on written submissions, or you may request to meet in-person for arbitration in Dallas, Texas. You agree that any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction.
</p>
<h6>Authority of Arbitrator</h6>
<p>The arbitrator shall have exclusive authority to (a) determine the scope and enforceability of this Arbitration Agreement and (b) resolve any dispute related to the interpretation, applicability, enforceability or formation of this Arbitration Agreement including, but not limited to any claim that all or any part of this Arbitration Agreement is void or voidable. The arbitration will decide the rights and liabilities, if any, of you and Global Goats. The arbitration proceeding will not be consolidated with any other matters or joined with any other cases or parties. The arbitrator shall have the authority to award monetary damages and to grant any non-monetary remedy or relief available to an individual under applicable law, the arbitral forum’s rules, and the Agreement (including the Arbitration Agreement). The arbitrator shall issue a written award and statement of decision describing the essential findings and conclusions on which the award is based, including the calculation of any damages awarded. The arbitrator has the same authority to award relief on an individual basis that a judge in a court of law would have. The award of the arbitrator is final and binding upon you and us. And you agree that to the extent monetary or non-monetary remedy or relief is granted, such request for relief may be enforced as needed by any court of competent jurisdiction.
</p>
<h6>Waiver of Jury Trial</h6>
<p>YOU AND Global Goats HEREBY WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO SUE IN COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY. You and Global Goats are instead electing that all claims and disputes shall be resolved by arbitration under this Arbitration Agreement, except as otherwise indicated in this Section 14. An arbitrator can award on an individual basis the same damages and relief as a court and must txfollow this Arbitration Agreement as a court would. However, there is no judge or jury in arbitration, and court review of an arbitration award is subject to very limited review.
</p>
<h6>Waiver of Class or Other Non-Individualized Relief</h6>
<p>ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED ON AN INDIVIDUAL BASIS AND NOT ON A OR COLLECTIVE CLASS BASIS, ONLY INDIVIDUAL RELIEF IS AVAILABLE, AND CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER. If a decision is issued stating that applicable law precludes enforcement of any part of this subsection’s limitations as to a given claim for relief, then that claim must be severed from the arbitration and brought in the state or federal courts located in the State of Texas. All other claims shall be arbitrated.
</p>
<h6>30-Day Right to Opt Out</h6>
<p>You have the right to opt out of the provisions of this Arbitration Agreement by sending written notice of your decision to opt out to the following address: Global Goats, LLC Email: globalgoatsnft@gmail.com, Attention Legal Department, within 30 days after first becoming subject to this Arbitration Agreement. You may also submit your decision to Hello@personalcorner.com. Your notice must include your name and address, the cryptocurrency wallet address you used to transact on this Site (if you have one), and an unequivocal statement that you want to opt out of this Arbitration Agreement. If you opt out of this Arbitration Agreement, all other parts of this Agreement will continue to apply to you. Opting out of this Arbitration Agreement has no effect on any other arbitration agreements that you may currently have, or may enter in the future, with us.
</p>
<h6>Severability</h6>
<p>Except as provided herein, if any part or parts of this Arbitration Agreement are found under the law to be invalid or unenforceable, then such specific part or parts shall be of no force and effect and shall be severed and the remainder of the Arbitration Agreement shall continue in full force and effect.
</p>
<h6>Survival of Agreement</h6>
<p>This Arbitration Agreement will survive the termination of your relationship with Global Goats.
</p>
<h6>Severability</h6>
<p>If any term, clause, or provision of these Terms is held invalid or unenforceable, then that term, clause, or provision shall be severable from these Terms and will not affect the validity or enforceability of any remaining part of that term, cause, or provision, or any other terms, clause, or provision of these Terms.</p>
<h6>Entire Agreement</h6>
<p>These Terms comprise the entire agreement between you and us relating to your access to and use of the Site, Site Content and any NFTs you have purchased, and supersede any and all prior discussions agreements, and understandings of any kind. Except as otherwise provided herein, these Terms are intended solely for the benefit of the parties and are not intended to confer third party beneficiary rights upon any other person or entity.
</p>
          </div>
        </section>
        <Footer/>
      </div>
    )
}
