import React from 'react'
import '../ResponsiveCss/Responsive.css';
import Image1 from '../../assets/images/ourTeam/1.png'
import Image2 from '../../assets/images/ourTeam/2.png'
import Image3 from '../../assets/images/ourTeam/3.png'
import Image4 from '../../assets/images/ourTeam/4.png'
import Image5 from '../../assets/images/ourTeam/5.png'

export default function OurTeam() {
    return (
            <div class="team_section" id="team">
              <div class="container">
                  <h2>OUR TEAM</h2>
                  <span>In a quest to uncover the Global GOAT’s of the sports world, 5 friends parlayed their unique skills
                   into a showcase for the best of the best.</span>
                 <div class="team-bottom-panel">
                  <div class="team-block">
                      <img src={Image1}></img>
                      <div class="follow-button"><a href="https://twitter.com/mikesullivan?ref_src=twsrc%5Etfw" class="twitter-follow-button" data-show-count="false" target="_blank">Follow @mikesullivan</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></div>
                       <h4>Mike</h4>
                       <h6>"The wolf"</h6>
                       <p>Never satisfied with good. Addicted to greatness. GOAT chasing.</p>
                   
                  </div>
                  <div class="team-block">
                      <img src={Image2}></img>
                      <div class="follow-button"><a href="https://twitter.com/KonanisPicks?ref_src=twsrc%5Etfw" class="twitter-follow-button" data-show-count="false" target="_blank">Follow @KonanisPicks</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></div>
                        <h4>Matt</h4>
                       <h6>"The Winner"</h6>
                       <p>Parlay palooza. No losers welcome. Discord dictator.</p>
                      
                  </div>
                  <div class="team-block">
                      <img src={Image3}></img>
                      <div class="follow-button"><a href="https://twitter.com/KyleBogey?ref_src=twsrc%5Etfw" class="twitter-follow-button" data-show-count="false" target="_blank">Follow @KyleBogey</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></div>
                      <h4>Kyle</h4>
                       <h6>"The Wise"</h6>
                       <p>If you’re not a GOAT, you shall not pass. </p>
                     
                  </div>
                  <div class="team-block">
                      <img src={Image4}></img>
                      <div class="follow-button"><a href="/#team">Follow</a></div>
                      <h4>Donny</h4>
                       <h6>"The Artist"</h6>
                       <p>Hand drawing and designing the entire GOAT community. </p>
                      
                  </div>
                  <div class="team-block">
                      <img src={Image5}></img>
                      <div class="follow-button"><a href="https://vibhuti.biz/contact/" class="twitter-follow-button" data-show-count="false" target="_blank">Follow</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></div>
                      <h4>Team</h4>
                       <h6>"Vibhuti Tech"</h6>
                       <p>Coding, blockchain developer, and bringing our GOATs to life.</p>
                      
                  </div>
                 </div>
              </div>
            </div>
    )
}
