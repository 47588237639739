import React from "react";
import Collapsible from 'react-collapsible';
// import '../commoncss/responsive.css';
import '../ResponsiveCss/Responsive.css';
import './Accordian.css'
import { BsChevronDown } from "react-icons/bs";

const Accordian = () => {
  return (
    <section class="sixth-section" id="faq">
    <div class="container">
       <h2>FAQ's</h2>
    	<div class="panel">
         <div className="accordion-panel">
            <div className="Collapsible__custom-sibling">

              <Collapsible key={1}
                trigger={["What is NFT?", <BsChevronDown />]}>
                <p>
                <b>Digital Asset -</b>NFT (Non-Fungible Token) is a digital asset that represents internet collectibles like art, music, and games with an authentic certificate created by blockchain technology that underlies Cryptocurrency.<br></br><br></br>
                <b>Unique - </b>It cannot be forged or otherwise manipulated.<br></br><br></br>
                <b>Exchange - </b>NFT exchanges take place in cryptocurrencies such as Bitcoin on specialist sites
                </p>
              </Collapsible>


              <Collapsible key={2}
                trigger={["How Do I buy a Global goats NFT?", <BsChevronDown />]}>
                <p>
                1. Download the MetaMask extension on your browser. We highly recommend you mint using a PC, as a mobile phone has potential to lag and are not optimized for minting. Global GOATs can download MetaMask here: <a href="http://metamask.io/download.html"></a><br></br><br></br>
                2. Buy cryptocurrency to purchase the Global GOAT NFT. Furthermore, Global GOATS use the Ethereum (ETH) blockchain for the Global GOAT NFT. Therefore, you will need Ethereum to purchase the Global GOAT NFT. You will need .11 Ethereum to make a purchase for the Global GOAT NFT Pre-Sale on January 30th at 9pm. You will also need extra Ethereum to cover gas fees, which are processing fees. The Global GOAT Public-Sale on the January 31st at 9pm will cost .15 Ethereum. You can purchase Ethereum directly in MetaMask, or you can purchase Ethereum on Binance or Coinbase <a href="http://binance.com/"></a> & <a href="http://coinbase.com/"></a> and transfer your ETH to your MetaMask wallet.<br></br><br></br> 
                3. Connect your MetaMask wallet on our website, (<a href="https://vibhutitechnologies.net/GoldenGoats/"></a>)<br></br><br></br>
                4. On January 30th, 2022, at 9pm EST, go to the Global GOATs website and click the ‘mint’ button. You will also need to select how many NFTs you wish to purchase. We do recommend purchasing more than one NFT from the Global GOAT collection as once they are sold out, there will be no more from this particular collection. Once you decide how many NFTs to purchase, this will open a MetaMask popup, and you will need to confirm the transaction in that pop-up.<br></br><br></br>
              </p>
          
            </Collapsible>

              <Collapsible key={3}
                trigger={["What Are the Details of the Global GOAT NFT?", <BsChevronDown />]}>
                <p>
                -  There will be 2323 Global GOAT NFTs available for purchase. Based off the legendary GOAT number of 23. Of the 2323 Global GOAT NFTs available, 100 will be Golden GOAT NFTs.
                  This will be our most exclusive design and will enable you to receive royalties upon re-sale of the NFT. <br></br> <br></br>   

                -  The Global GOAT NFT Pre-Sale will be available on January 30th, 2022, at 9pm. The pre-sale mint is .11 ETH. The Public-Sale mint will be available January 31st, 2022, at 9pm and will be .15 Ethereum.<br></br><br></br>
                -  All minted GOATs will be available on OpenSea and Global GOAT holders will be eligible for giveaways within the Global GOAT Road Map.<br></br><br></br>
                </p>
              </Collapsible>
              <Collapsible key={4}
                trigger={["How Can I Be Sure That You Donate?", <BsChevronDown />]}>
                <p>
                - We will be posting the proof and screenshot inside of our Discord channel! <br></br> <br></br>  

        </p>
              </Collapsible>
            </div>
          </div>
        </div>
    </div>
</section>
   
  )
}

export default Accordian;