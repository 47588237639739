import React, { useEffect, useState } from 'react';
import { useHistory,useLocation } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useEthers } from "@usedapp/core";
import { doc, setDoc,getDoc,updateDoc } from "firebase/firestore";
import { db } from "./../../config/firebase-config";
import { getApp } from "firebase/app";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

import './EditNFTForm.css';
import { FontAwesome } from 'react-web-vector-icons';
import Footer from '../../components/Footer/Footer.js';
import Navbar from '../../components/NavBar/Navbar.js';

const CreateNFT = () => {
  let history = useHistory();
  const location = useLocation();
  const [formTokenID, setFormTokenID] = useState('');
  const [nftName, setNftName] = useState('');
  const [nftPrice, setNftPrice] = useState('');
  const [nftDescription, setNftDescription] = useState('');
  const [nftImage, setNftImage] = useState('');
  const [activityStatus, setActivityStatus] = useState(false);
  const firebaseApp = getApp();
  const storage = getStorage(firebaseApp);
  
  useEffect(() => {
    console.log(location.pathname); // result: '/secondpage'
    console.log(location.search); // result: '?query=abc'
    console.log(location.state.detail); // result: 'some_value'
    window.localStorage.setItem('state', JSON.stringify(location.state.detail));
 }, [location]);

  useEffect(() => {
    getNFTDetail()
  },[location]);

  function isInt(str) {
    return !isNaN(str) && Number.isInteger(parseFloat(str));
  }

  const getNFTDetail = async() => {
    event.preventDefault();
    var getItem = JSON.parse(window.localStorage.getItem('state')) ||
    console.log("getItem",JSON.parse(getItem))
    var idToget=getItem;
    const docRef = doc(db, "NFTList", idToget);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      const NFTDetails = docSnap.data();
      console.log("NFTDetails",NFTDetails)
      setNftName(NFTDetails.nftName)
      setNftImage(NFTDetails.nftImage)
      setNftDescription(NFTDetails.nftDescription)
      // setNftPrice(NFTDetails.nftPrice)
      setFormTokenID(NFTDetails.tokenID)
    //   setContractABI(contractDetails.contractAbi);
    //   setChainName(contractDetails.network);
    //   setContractAddress(contractDetails.contractAddress);
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  }


  const UpdateNFT = async() => {
    if(
        nftDescription.trim()=="" || nftDescription.trim()==null
        || nftName.trim()=="" || nftName.trim()==null
        || formTokenID.trim()=="" || formTokenID.trim()==null
    ){
        alert("All fields are required")
    } else if (!isInt(formTokenID) || parseFloat(formTokenID)<=0) {
        alert("Invalid Entries! Please check all details again.")
    } else {
      var nftImageUrl = nftImage;
      if(nftImage.name){
        const storageRef = ref(storage, '/Images/NFTImages/' + nftName + "_" + formTokenID + ".png");
        await uploadBytes(storageRef, nftImage);
        await getDownloadURL(storageRef).then((url)=>{nftImageUrl = url});  
        console.log("nftImageUrl ======== "+nftImage+" ------ "+ nftImageUrl)
      }

      const washingtonRef = doc(db, "NFTList", formTokenID);
      var NFTDetails = {
        id: formTokenID,
        nftDescription: nftDescription.trim(),
        nftImage: nftImageUrl.trim(),
        // nftPrice: parseFloat(nftPrice),
        nftName: nftName.trim(),
        tokenID: formTokenID,
        tokenUri: "",
        transactionStatus: 0
      }

      // // Set the "capital" field of the city 'DC'
      const aaa = await updateDoc(washingtonRef, NFTDetails);
      // history.goBack();
      alert("NFT Successfully Updated.");
    }
  }

  const onHeaderChoose = event => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setNftImage(img)
      console.log(img)
    }
  };

  return (
    <div>

    <div className={"root-div"}>
    <div className={"sub-root-div12"} style={{marginBottom:100}}>
      <div className={"homepage-head"} style={{height: '100%', width: '100%'}}>
        <div className={"h1-style1"}>{"Edit NFT"}</div>
      </div>
      <div style={{ marginBottom: 30, marginTop:60, }}>
        <span style={{ fontSize: 24, fontWeight: 'bold', borderRadius:50, padding: '5px 30px', backgroundColor: '#eee' }}>{'NFT'}</span>
      </div>
      <div className='grey-box-css'>
          <div style={{marginBottom: 30, padding:20, display:'flex', flexDirection:'column'}}>
            <div className='inside-modal-input'>
              <label style={{flex:0.4, textAlign:'left', fontWeight:'bold', marginRight:20}}>Token ID</label>
              <input 
                style={{flex:0.6, padding: '10px 15px', minWidth:'150px'}}
                type="text"
                placeholder='Token ID'
                value={formTokenID}
                onChange={(e) => {setFormTokenID(e.target.value)}}
              />
            </div>
            <div className='inside-modal-input'>
              <label style={{flex:0.4, textAlign:'left', fontWeight:'bold', marginRight:20}}>NFT Name</label>
              <input 
                style={{flex:0.6, padding: '10px 15px', minWidth:'150px'}}
                type="text" 
                placeholder='NFT Name'
                name="nftName"
                value={nftName}
                onChange={(e) => {setNftName(e.target.value)}}
              />
            </div>
            <div className='inside-modal-input'>
              <label style={{flex:0.4, textAlign:'left', fontWeight:'bold', marginRight:20}}>NFT Image</label>
              <div style={{flex:0.6}}>
                <input style={{marginBottom:20}} onChange={onHeaderChoose} type="file" name="myImage"/>
                {nftImage && (<><br/>
                  <img src={nftImage.name?URL.createObjectURL(nftImage):nftImage} style={{height:150, padding:10, backgroundColor:'#000'}} alt="NFT Image"/></>)}
              </div>
            </div>
            {/* <div className='inside-modal-input'>
              <label style={{flex:0.4, textAlign:'left', fontWeight:'bold', marginRight:20}}>NFT Price</label>
              <input 
                style={{flex:0.6, padding: '10px 15px', minWidth:'150px'}}
                type="textarea" 
                placeholder='NFT Price'
                name="nftPrice"
                value={nftPrice}
                onChange={(e) => {setNftPrice(e.target.value)}}
              />
            </div> */}
            <div className='inside-modal-input'>
              <label style={{flex:0.4, textAlign:'left', fontWeight:'bold', marginRight:20}}>NFT Description</label>
              <textarea 
                style={{flex:0.6, padding: '10px 15px', maxHeight:100, minHeight:100}}
                type="text" 
                placeholder='NFT Description'
                name="nftDescription"
                value={nftDescription}
                onChange={(e) => {setNftDescription(e.target.value)}}
                rows={5}
                cols={5}
              />
            </div>
          </div>
          <button onClick={()=>{UpdateNFT()}} style={{backgroundColor:'#000', color:'#fff', display:'flex', width:'100%', padding: '10px', justifyContent:'center', border:'none', borderRadius:50}}>
            <span style={{fontSize:18, fontWeight:'bold'}}>Update NFT</span>
          </button>
      </div>
    </div>
    </div>
 
    </div>
  );
};

export default CreateNFT;