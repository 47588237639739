import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useEthers } from "@usedapp/core";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "./../../config/firebase-config";
import './AdminPanel.css';
import { FontAwesome } from 'react-web-vector-icons';
import Footer from '../../components/Footer/Footer.js';
import Navbar from '../../components/NavBar/Navbar.js';
import AdminHeader from './AdminHeader.js';
import Sidebar from './Sidebar.js';

const AdminPanel = () => {
  const [filterModel, setFilterModal] = useState(false);
  const [nameAesc, setNameAesc] = useState(true);
  let history = useHistory();
  const { activateBrowserWallet, account, deactivate } = useEthers();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [contractABI, setContractABI] = useState('');
  const [chainName, setChainName] = useState('');
  const [contractAddress, setContractAddress] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  function handleChangeABI(event) {
    console.log(event.target.value)
    setContractABI(event.target.value)
  }
  function handleChangeNetwork(event) {
    console.log(event.target.value)
    setChainName(event.target.value)
  }
  function handleChangeAddress(event) {
    console.log(event.target.value)
    setContractAddress(event.target.value)
  }
  
  useEffect(async() => {
    // getListOfNFT()
    SmartContractDetails()
  }, []);

  const SmartContractDetails = async() => {
    const docRef = doc(db, "Settings", "27");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      const contractDetails = docSnap.data();
      setContractABI(contractDetails.contractAbi);
      setChainName(contractDetails.network);
      setContractAddress(contractDetails.contractAddress);
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  }

  const UpdateSmartContract = async() => {
    if(
      contractABI.trim()=="" || contractABI.trim()==null
      || contractAddress.trim()=="" || contractAddress.trim()==null
      || chainName.trim()=="" || chainName.trim()==null
    ){
        alert("All fields are required")
    } else {
      const washingtonRef = doc(db, "Settings", "27");

      // Set the "capital" field of the city 'DC'
      const aaa = await updateDoc(washingtonRef, {
        contractAbi: contractABI,
        network: chainName,
        contractAddress: contractAddress
      });
      handleClose();
      alert("Updated Successfully.");
    }
  }

  return (
    <div>
          {/* <Navbar height={innerHeight} width={innerWidth} /> */}
    <div>
    <div>   
         <Sidebar/>
      {/* <div className={"homepage-head"} style={{height: '100%', width: '100%'}}>
        <div className={"h1-style1"}>{"ADMIN PANEL"}</div>
      </div> */}
      <div className={"grid-col121"}>
        <div className={"grid-col-item121"} onClick={() => handleShow1()}>
          <button type="button" name="filter-icon" className={"filter-icon-css1"}>
            <FontAwesome
              name='arrows'
              color='white'
              size={70}
            />  
          </button>
          <div className={"admin--grid-text"}>Dynamic Pages</div>
        </div>
        <div className={"grid-col-item121"} onClick={() => handleShow()}>
          <button type="button" name="filter-icon" className={"filter-icon-css1"}>
            <FontAwesome
              name='book'
              color='white'
              size={70}
            />   
          </button>
          <div className={"admin--grid-text"}>Smart Contract</div>
        </div>
        <div className={"grid-col-item121"}>
          <button type="button" onClick={()=>{ history.push('/EditNFT')}} name="filter-icon" className={"filter-icon-css1"}>
            <FontAwesome
              name='database'
              color='white'
              size={70}
            />
          </button>
          <div className={"admin--grid-text"}>Platform NFT</div>
        </div>
      </div>
      <Modal
        open={show1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className='nft-details-modal111'>
          <Box className='nft-details-modal-content111'>
            <div style={{flexDirection:'row', paddingBottom:20, display:'flex', alignItems:'center', justifyContent:'space-between'}}>
              <span style={{fontSize:24, fontWeight:'bold', fontFamily:'sans-serif'}}>{"Dynamic Pages"}</span>
              <button className="visually-hidden-button" onClick={handleClose1}><i class="fa fa-close"></i></button> 
            </div>
            <div className={"grid-col1111"}>
              {/* <div className={"grid-col-item113"} onClick={() => { history.push('/EditHomePage')}}>
                <button type="button" name="filter-icon" className={"filter-icon-css1"}>
                  <FontAwesome
                    name='home'
                    color='white'
                    size={70}
                  />   
                </button>
                <div className={"admin--grid-text"}>Home Page</div>
              </div> */}
              <div className={"grid-col-item113"}>
                <button type="button" name="filter-icon" className={"filter-icon-css1"}>
                  <FontAwesome
                    name='navicon'
                    color='white'
                    size={70}
                  />
                </button>
                <div className={"admin--grid-text"}>Navigator</div>
              </div>
            </div>
          </Box>
        </div>
      </Modal>
      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className='nft-details-modal222'>
          <Box className='nft-details-modal-content222'>
            <div style={{flexDirection:'row', paddingBottom:20, display:'flex', alignItems:'center', justifyContent:'space-between'}}>
              <span style={{fontSize:24, fontWeight:'bold', fontFamily:'sans-serif'}}>{"Smart Contract"}</span>
              <button className="visually-hidden-button" onClick={handleClose}><i class="fa fa-close"></i></button> 
            </div>
            <div style={{ marginBottom: 30, marginTop: 20, fontSize: 24, fontWeight: 'bold', borderRadius:50, padding: '5px 30px', backgroundColor: '#eee', textAlign:'center' }}>
              <span style={{  }}>{'Contract Details'}</span>
            </div>
            <div style={{backgroundColor:'#eee', marginBottom: 30, padding:20, borderRadius:10, display:'flex', flexDirection:'column'}}>
              <div className='inside-modal-input'>
                <label style={{flex:0.4, textAlign:'left', fontWeight:'bold', marginRight:20}}>Contract ABI</label>
                <textarea 
                  style={{flex:0.6, padding: '10px 15px', maxHeight:250, minHeight:250}}
                  type="text" 
                  name="contractABI"
                  value={contractABI}
                  onChange={handleChangeABI}
                  rows={5}
                  cols={5}
                />
              </div>
              <div className='inside-modal-input'>
                <label style={{flex:0.4, textAlign:'left', fontWeight:'bold', marginRight:20}}>Chain Network Name</label>
                <input 
                  style={{flex:0.6, padding: '10px 15px', minWidth:'150px'}}
                  type="text" 
                  name="chainName"
                  value={chainName}
                  onChange={handleChangeNetwork}
                />
              </div>
              <div className='inside-modal-input'>
                <label style={{flex:0.4, textAlign:'left', fontWeight:'bold', marginRight:20}}>Contract Address</label>
                <input 
                  style={{flex:0.6, padding: '10px 15px', minWidth:'150px'}}
                  type="textarea" 
                  name="contractAddress"
                  value={contractAddress}
                  onChange={handleChangeAddress}
                />
              </div>
            </div>
            <button onClick={()=>{UpdateSmartContract()}} style={{backgroundColor:'#000', color:'#fff', display:'flex', width:'100%', padding: '10px', justifyContent:'center', border:'none', borderRadius:50}}>
              <span style={{fontSize:18, fontWeight:'bold'}}>Update Contract</span>
            </button>
          </Box>
        </div>
      </Modal>
    </div>
    </div>
    {/* <Footer/> */}
    </div>
  );
};

export default AdminPanel;